import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authLogin, socialAuthLogin, getAuthState } from "../../store/Slices/AuthSlice";
import { emailRegex } from "../shared/constants";
import FormError from "../shared/FormError";
import { useThunk } from "../../Hooks/use-thunk";
import Loader from "./common/Loader";
import { HiHome } from "react-icons/hi";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { ImFacebook2 } from "react-icons/im";

import { auth, googleProvider, facebookProvider } from "./login/config";
import { signInWithPopup } from "firebase/auth";

const Login = () => {
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const [passType, setPassType] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { isLoggedIn, user } = useSelector(getAuthState);

  const [loginUser, isLoading, loginError] = useThunk(authLogin);
  const [socialLogin, isSocialLoginLoading, socialLoginError] = useThunk(socialAuthLogin);

  useEffect(() => {
    if (isLoggedIn) {
      if (state?.path) {
        navigate(state?.path);
      } else {
        if (user && user.Role === "Admin") {
          navigate("/admin");
        } else {
          navigate("/", { state: { prevPath: "login" } });
        }
      }
    }
  }, [isLoggedIn, navigate, state?.path, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({ ...prev, [name]: value }));

    if (name === "email") {
      if (value) {
        if (emailRegex.test(value)) {
          setError((prev) => ({ ...prev, [name]: "" }));
        } else {
          setError((prev) => ({
            ...prev,
            [name]: "Please Enter valid email.",
          }));
        }
      } else {
        setError((prev) => ({
          ...prev,
          [name]: "This field is required.",
        }));
      }
    } else {
      if (value) {
        setError((prev) => ({ ...prev, [name]: "" }));
      } else {
        setError((prev) => ({
          ...prev,
          [name]: "This field is required.",
        }));
      }
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    let isValidate = false;
    if (Object.values(error).join("")) {
      return;
    }

    if (!loginData.email) {
      isValidate = false;
      setError((prev) => ({ ...prev, email: "This field is required." }));
    } else {
      isValidate = true;
    }

    if (!loginData.password) {
      isValidate = false;
      setError((prev) => ({ ...prev, password: "This field is required." }));
    } else {
      isValidate = true;
    }

    if (!isValidate) {
      return;
    }

    const payload = new FormData();
    payload.append("stEmail", loginData.email);
    payload.append("stPassword", loginData.password);
    loginUser(payload);
  };

  const handleNavigateRegister = () => {
    if (state?.path) {
      navigate("/register", { state: { path: state?.path } });
    } else {
      navigate("/register");
    }
  };

  const handleSocialData = (data) => {
    const { user } = data;
    const { displayName, email, phoneNumber, photoURL, providerId, uid } =
      user.providerData?.[0];

    const payload = new FormData();

    payload.append("displayName", displayName);
    payload.append("email", email);
    payload.append("phoneNumber", phoneNumber);
    payload.append("socialUid", uid);
    payload.append("socialProvider", providerId);
    
    socialLogin(payload);
  }

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, googleProvider).then((data) => {
      handleSocialData(data);
    });
  };

  const handleFacebookSignIn = () => {
    signInWithPopup(auth, facebookProvider).then((data) => {
      handleSocialData(data);
    });
  };

  return (
    <div className="h-[100vh] flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-tr from-gray-50">
      {isLoading && (
        <div className="fixed z-50 inset-0 bg-[#ffffffa9] flex justify-center items-center">
          <Loader />
        </div>
      )}
      <div className="w-full max-w-md">
        <div className="text-center">
          <button
            type="button"
            onClick={() => navigate("/")}
            className="text-center bg-slate-300 p-3.5 rounded-full inline-block"
          >
            <HiHome className="text-gray-700 text-[20px]" />
          </button>
          <h2 className="mt-1 text-center text-[22px] font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <form
          className="mt-6 bg-white p-6 rounded-[0px] shadow-2xl"
          onSubmit={handleLogin}
        >
          {loginError && (
            <div className="rounded mb-3 border border-red-400 bg-red-100 text-red-600 italic text-sm p-2">
              Incorrect email or password.
            </div>
          )}
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md">
            <div className="mb-6">
              <label htmlFor="email-address" className="text-[14px] font-bold">
                Email address <span className="text-red-600">*</span>
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                className="block w-full outline-none mt-2 rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 sm:text-sm"
                placeholder="Email address"
                onChange={handleChange}
              />
              {error.email && <FormError>{error.email}</FormError>}
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="text-[14px] font-bold">
                Password <span className="text-red-600">*</span>
              </label>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  type={passType ? "text" : "password"}
                  autoComplete="current-password"
                  className="relative block w-full mt-2 outline-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 sm:text-sm"
                  placeholder="Password"
                  onChange={handleChange}
                />
                {error.password && <FormError>{error.password}</FormError>}
                <button
                  type="button"
                  onClick={() => setPassType(!passType)}
                  className={`absolute ${
                    error.password ? "top-[30%]" : "top-[50%]"
                  } -translate-y-1/2 right-3`}
                >
                  {passType ? <BsEyeSlash /> : <BsEye />}
                </button>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between mt-[12px]">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 "
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-800"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <span
                className="font-medium cursor-pointer text-gray-700"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot your password?
              </span>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center border border-transparent bg-gray-900 py-2 px-4 text-sm font-medium text-white hover:bg-white hover:border hover:border-gray-900 hover:text-gray-900 duration-500 focus:outline-none mt-[14px]"
            >
              Sign in
            </button>
            {/* <button
                            type="submit"
                            className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-[14px]"
                        >
                            Sign in
                        </button> */}
          </div>

          <div className="flex justify-center gap-4">
            <button
              onClick={handleGoogleSignIn}
              type="button"
              className="group relative flex items-center p-3 px-0 border bg-[#FFF] text-sm font-medium text-gray-800 focus:outline-none mt-3 border-gray-600"
            >
              <FcGoogle className="w-16" />
            </button>

            {/* <button
              onClick={handleFacebookSignIn}
              type="button"
              className="group relative flex items-center p-3 px-0 rounded-md border bg-[#FFF] text-sm font-medium text-gray-800 focus:outline-none mt-3 border-gray-600"
            >
              <ImFacebook2 className="w-16" />
            </button> */}
          </div>

          {/* <span
            className="flex justify-center font-medium cursor-pointer text-sm mt-2"
            onClick={handleNavigateRegister}
          >
            Don't have an Account? 
          </span> */}

          <div className="flex justify-center gap-1 text-center text-[14px] text-gray-500 mt-2">
            Don't have an Account?{" "}
            <p
              onClick={() => handleNavigateRegister()}
              className="text-gray-700 font-bold cursor-pointer"
            >
              Sign up
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
