import React from "react";
import { useState } from "react";
import Pagination from "./Pagination";
import { TiArrowBack } from "react-icons/ti";
import { AiOutlineDelete } from "react-icons/ai";
import AddAttribute from "./Modals/AddAttribute";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchAttributeData,
  getAttributeState,
} from "./../../store/Slices/attributeSlice";
import { DeleteAttributeData } from "../../Services/attributeService";
import { toast } from "react-hot-toast";
import { BiEdit } from "react-icons/bi";
import { GoDiffAdded, GoKebabVertical } from "react-icons/go";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BsCardChecklist, BsPlusLg } from "react-icons/bs";
import AddAttributeValue from "./Modals/AddAttributeValue";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import usePagination from "../../Hooks/use-Pagination";
import Loader from "./common/Loader";

const AttributeList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAtOpen, setIsAtOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const [attributeId, setAttributeId] = useState("");
  const [attributeName, setAttributeName] = useState("");
  const [selectDiamond, setSelectDiamond] = useState("");
  const dispatch = useDispatch();
  const { data, loading } = useSelector(getAttributeState);
  const navigate = useNavigate();
  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data,
      itemsPerPage: 10,
    });

  useEffect(() => {
    dispatch(fetchAttributeData());
  }, [dispatch]);

  const handleEdit = (id) => {
    setEditId(id);
    setIsOpen(true);
  };

  const handleOpenAttribute = (id, name, isdiamond) => {
    setIsAtOpen(true);
    setAttributeId(id);
    setAttributeName(name);
    setSelectDiamond(isdiamond);
  };

  console.log("sele", selectDiamond);

  const handleDelete = async (id) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this value ?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });

    if (willDelete) {
      const res = await DeleteAttributeData(id);
      if (res.status === 200) {
        if (res.status === 200) {
          toast.success(res.data.message);
          dispatch(fetchAttributeData());
        }
        dispatch(fetchAttributeData());
      }
    }
  };

  return (
    <div className="flex flex-col flex-1 w-full h-[90vh] bg-gray-100">
      <AddAttributeValue
        isAtOpen={isAtOpen}
        setIsAtOpen={setIsAtOpen}
        attributeName={attributeName}
        attributeId={attributeId}
        selectDiamond={selectDiamond}
        setSelectDiamond={setSelectDiamond}
        setEditId={setEditId}
      />
      <AddAttribute
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        editId={editId}
        setEditId={setEditId}
      />
      <main className="h-full overflow-y-auto">
        <div className="grid px-6 pb-6 mx-auto">
          <div className="flex items-center justify-between gap-[10px]">
            <Link
              to={"/admin/masterMenu"}
              className="rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
            >
              <TiArrowBack className="text-[18px]" /> Back
            </Link>
            <button
              onClick={() => setIsOpen(true)}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc]"
            >
              <BsPlusLg className="text-[10px]" /> Add
            </button>
          </div>
          <div className="shadow-md sm:rounded-lg overflow-hidden relative mt-4 rounded">
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-4 w-[20px]">
                      Sr.
                    </th>
                    <th scope="col" className="px-4 py-4 w-[150px]">
                      Design Name
                    </th>
                    <th scope="col" className="px-4 py-4 w-[150px]">
                      Stock Name
                    </th>
                    <th scope="col" className="px-4 py-4">
                      Description
                    </th>
                    <th scope="col" className="px-4 py-4 w-20 text-center">
                      Y | N
                    </th>
                    <th scope="col" className="px-4 py-4 w-[100px] text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                    currentData.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                        >
                          <th
                            scope="row"
                            className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {i + 1}
                          </th>
                          <th
                            scope="row"
                            className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.stAttributeName}
                          </th>
                          <th
                            scope="row"
                            className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.stAttributeStock}
                          </th>
                          <th
                            scope="row"
                            className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.stAttributeDesc}
                          </th>
                          <td className="px-4 py-3 text-center">
                            {item.flgIsActive === "1" ||
                            item.flgIsActive === 1 ? (
                              <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                Y
                              </span>
                            ) : (
                              <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                N
                              </span>
                            )}
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex items-center gap-[10px]">
                              <button
                                onClick={handleEdit.bind(
                                  null,
                                  item.unAttributeIdentifier
                                )}
                                type="button"
                              >
                                <BiEdit className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                              </button>
                              <button
                                type="button"
                                onClick={handleDelete.bind(
                                  null,
                                  item.unAttributeIdentifier
                                )}
                              >
                                <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                              </button>
                              <div className="flex items-center justify-end">
                                <Menu
                                  as="div"
                                  className="h-[20px] text-left relative"
                                >
                                  <Menu.Button>
                                    <GoKebabVertical className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[3px] rounded" />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute top-3 z-50 right-0 mt-2 w-56  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="px-1 py-1 ">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              className={`${
                                                active
                                                  ? "bg-[#94a3b8] text-white"
                                                  : "text-gray-900"
                                              } group flex w-full items-center gap-[5px] rounded-md px-2 py-1.5 text-[13px] font-Poppins`}
                                              onClick={() =>
                                                handleOpenAttribute(
                                                  item.unAttributeIdentifier,
                                                  item.stAttributeName,
                                                  item.flgIsSelectDiamond
                                                )
                                              }
                                            >
                                              <GoDiffAdded className="text-[16px]" />
                                              Add Attribute Value
                                            </button>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              className={`${
                                                active
                                                  ? "bg-[#94a3b8] text-white"
                                                  : "text-[#1e293b]"
                                              } group flex w-full items-center gap-[5px] rounded-md px-2 py-1.5 text-[13px] font-Poppins`}
                                              onClick={() =>
                                                navigate(
                                                  `/admin/attributevaluelist/${item.unAttributeIdentifier}`,
                                                  {
                                                    state: {
                                                      name: item.stAttributeName,
                                                      selectDiamond:
                                                        item.flgIsSelectDiamond,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              <BsCardChecklist className="text-[16px]" />
                                              Attribute Value List
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  {loading && (
                    <tr className="bg-white">
                      <td colSpan={6} className="h-60">
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              totalItems={data.length}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default AttributeList;
