import React from "react";
import AdminModal from "../common/AdminModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAttributeData,
  getAttributeState,
} from "../../../store/Slices/attributeSlice";
import { useState } from "react";
import { useEffect } from "react";
import {
  createAttributeData,
  updateAttributeData,
} from "../../../Services/attributeService";
import { toast } from "react-hot-toast";

const AddAttribute = ({ isOpen, setIsOpen, editId, setEditId }) => {
  const [inputValue, setInputValue] = useState({
    name: "",
    stockName: "",
    discription: "",
    isSelectDiamond: false,
    isActive: true,
  });
  const dispatch = useDispatch();
  const { data } = useSelector(getAttributeState);

  useEffect(() => {
    const editData = data.find((item) => item.unAttributeIdentifier === editId);
    if (editData) {
      setInputValue({
        name: editData.stAttributeName,
        stockName: editData.stAttributeStock,
        discription: editData.stAttributeDesc,
        isSelectDiamond: editData.flgIsSelectDiamond === "1" || editData.flgIsSelectDiamond === 1 ? true : false,
        isActive: editData.flgIsActive === "1" || editData.flgIsActive === 1 ? true : false,
      });
    }
  }, [editId, data]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: (name === "isActive" || name === "isSelectDiamond") ? checked : value,
    }));
  };

  const handleClose = () => {
    setEditId("");
    setInputValue({
      name: "",
      stockName: "",
      discription: "",
      isSelectDiamond: false,
      isActive: true,
    });
  };

  console.log("+++++++++", inputValue);

  const handleSubmit = async () => {
    if (editId) {
      const payload = {
        stAttributeName: inputValue.name,
        stAttributeStock: inputValue.stockName,
        stAttributeDesc: inputValue.discription,
        flgIsSelectDiamond: inputValue.isSelectDiamond ? "1" : "0",
        flgIsActive: inputValue.isActive ? "1" : "0",
        unAttributeIdentifier: editId,
      };
      const res = await updateAttributeData(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchAttributeData());
      }
    } else {
      const payload = new FormData();
      payload.append("stAttributeName", inputValue.name);
      payload.append("stAttributeStock", inputValue.stockName);
      payload.append("stAttributeDesc", inputValue.discription);
      payload.append("flgIsSelectDiamond", inputValue.isSelectDiamond ? "1" : "0");
      payload.append("flgIsActive", inputValue.isActive ? "1" : "0");
      const res = await createAttributeData(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchAttributeData());
      }
    }
    handleClose();
  };

  return (
    <AdminModal
      title={editId ? "Update Attribute" : "Add Attribute"}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={() => {setIsOpen(false); handleClose()}}
      submitButtonText={editId ? "Update" : "Save"}
    >
      <div className="mb-4">
        <label
          htmlFor="attribute"
          className="block text-sm font-medium leading-4 text-gray-900"
        >
          Attribute
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="name"
            value={inputValue.name}
            id="attribute"
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Enter Attribute"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="stockName"
          className="block text-sm font-medium leading-4 text-gray-900"
        >
          Attribute Stock Name
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="stockName"
            value={inputValue.stockName}
            id="stockName"
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Enter Attribute"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="discription"
          className="block text-sm font-medium leading-4 text-gray-900"
        >
          Description
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="discription"
            value={inputValue.discription}
            id="discription"
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Enter Description"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-2">
        <div className="flex items-center gap-[10px]">
          <input
            type="checkbox"
            name="isSelectDiamond"
            checked={inputValue.isSelectDiamond}
            onChange={handleChange}
            id="isSelectDiamond"
            className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
          />
          <label
            htmlFor="isSelectDiamond"
            className=" text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Set Select Your Diamond Button Below
          </label>
        </div>
      </div>
      <div className="mb-2">
        <div className="flex items-center gap-[10px]">
          <input
            type="checkbox"
            name="isActive"
            checked={inputValue.isActive}
            onChange={handleChange}
            id="attributeCheck"
            className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
          />
          <label
            htmlFor="attributeCheck"
            className=" text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            is Active
          </label>
        </div>
      </div>
    </AdminModal>
  );
};

export default AddAttribute;
