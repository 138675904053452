import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ChartBarSquareIcon,
  Cog6ToothIcon,
  FolderIcon,
  GlobeAltIcon,
  ServerIcon,
  SignalIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  HeartIcon,
  CubeTransparentIcon,
  WalletIcon,
  // HiHome,
  ChevronRightIcon,
  ArrowLeftIcon,
  CubeIcon,
} from "@heroicons/react/20/solid";
import { HiHome } from "react-icons/hi2";
import { GrHomeRounded } from "react-icons/gr";
import { Disclosure } from "@headlessui/react";

import cartIcon from "../../../assets/images/cartIcon.svg";
import homeIcon from "../../../assets/images/homeIcon.svg";
import { IoCart } from "react-icons/io5";

import { useNavigate } from "react-router-dom";

import {
  fetchCalibratedData,
  setSelectedShape,
} from "../../../store/Slices/calibratedSlice";
import { selectCartState, openCart } from "../../../store/Slices/cartSlice";

import { useDispatch, useSelector } from "react-redux";

import PlaceOrderModal from "./placeOrderModal";

import CardList from "./cardList";
import TableList from "./tableList";
import CartDrawer from "./cartDrawer";
import { useCallback } from "react";

import "./calibrated.css";

// const navigation = [
//   {
//     key: 0,
//     name: "Asscher",
//     href: "#",
//     icon: "Asscher.png",
//     current: false,
//   },
//   {
//     key: 1,
//     name: "Buguette",
//     href: "#",
//     icon: "Asscher.png",
//     current: false,
//   },
//   {
//     key: 2,
//     name: "Emerald",
//     href: "#",
//     icon: "Emerald.png",
//     current: false,
//   },
//   {
//     key: 3,
//     name: "Princess",
//     href: "#",
//     icon: "Princess.png",
//     current: false,
//   },
//   {
//     key: 4,
//     name: "Trapozaid",
//     href: "#",
//     icon: "Asscher.png",
//     current: false,
//   },
// {
//   key: 0,
//   name: "Asscher",
//   href: "#",
//   icon: "Asscher.png",
//   current: false,
// },
// {
//   key: 1,
//   name: "Cushion",
//   href: "#",
//   icon: "Cushion.png",
//   current: false,
// },
// {
//   key: 2,
//   name: "Emerald",
//   href: "#",
//   icon: "Emerald.png",
//   current: false,
// },
// {
//   key: 3,
//   name: "Heart",
//   href: "#",
//   icon: "Heart.png",
//   current: false,
// },
// {
//   key: 4,
//   name: "Marquise",
//   href: "#",
//   icon: "Marquise.png",
//   current: false,
// },
// {
//   key: 5,
//   name: "Oval",
//   href: "#",
//   icon: "Oval.png",
//   current: false,
// },
// {
//   key: 6,
//   name: "Pear",
//   href: "#",
//   icon: "Pear.png",
//   current: false,
// },
// {
//   key: 7,
//   name: "Princess",
//   href: "#",
//   icon: "Princess.png",
//   current: false,
// },
// {
//   key: 8,
//   name: "Radiant",
//   href: "#",
//   icon: "Radiant.png",
//   current: false,
// },
// {
//   key: 9,
//   name: "Round",
//   href: "#",
//   icon: "Round.png",
//   current: false,
// },
// {
//   key: 10,
//   name: "Trillion",
//   href: "#",
//   icon: "Trillion.png",
//   current: false,
// },
// ];

// const pairsData = [
//   {
//     key: 11,
//     shapeCode: "123",
//     name: "Half Moon",
//     href: "#",
//     icon: "",
//     current: false,
//     shapeSieveMM: "",
//   },
//   {
//     key: 12,
//     shapeCode: "456",
//     name: "Cadilac",
//     href: "#",
//     icon: "",
//     current: false,
//     shapeSieveMM: "",
//   },
//   {
//     key: 13,
//     shapeCode: "789",
//     name: "Trapezoid",
//     href: "#",
//     icon: "",
//     current: false,
//     shapeSieveMM: "",
//   },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Calibrated() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const shapes = useSelector((state) => state?.calibrated?.data?.Shapes);
  const selectedShape = useSelector(
    (state) => state?.calibrated?.selectedShape
  );
  const { cart, cartDrawer } = useSelector(selectCartState);

  const [state, setState] = useState({
    currentNavKey: null,
    currentShape: {},
    currentSize: {},
    standardData: [],
    pairsData: [],
    loading: false,
    openPlaceOrderModal: false,
  });

  let {
    currentNavKey,
    currentShape,
    currentSize,
    standardData,
    pairsData,
    loading,
    openPlaceOrderModal,
  } = state;

  const getData = async () => {
    await dispatch(fetchCalibratedData());
  };

  const handleOpenCart = () => {
    dispatch(openCart(true));
  };

  useEffect(() => {
    if (!shapes) {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      getData();
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  }, [shapes]);

  useEffect(() => {
    if (shapes?.length) {
      let standardList = [];
      let pairsList = [];

      let shapeList = shapes
        ?.map((item) => item)
        ?.sort((a, b) => ((a?.SortOrder || 2) > (b?.SortOrder || 2) ? 1 : -1))
        ?.map((item, i) => {
          if (item?.ShapeType?.toLowerCase() === "standard") {
            standardList.push({
              key: i,
              shapeCode: item?.ShapeCode,
              name: item?.Shape,
              href: "#",
              icon: item?.ImageURL,
              current: false,
              shapeSieveMM: item?.ShapeSieveMM,
            });
          } else {
            pairsList.push({
              key: i,
              shapeCode: item?.ShapeCode,
              name: item?.Shape,
              href: "#",
              icon: item?.ImageURL,
              current: false,
              shapeSieveMM: item?.ShapeSieveMM,
            });
          }

          return {
            key: i,
            shapeCode: item?.ShapeCode,
            name: item?.Shape,
            href: "#",
            icon: item?.ImageURL,
            current: false,
            shapeSieveMM: item?.ShapeSieveMM,
          };
        });

      setState((prevState) => ({
        ...prevState,
        standardData: standardList,
        pairsData: pairsList,
        currentNavKey: shapeList[0]?.key,
        currentShape: shapeList[0],
      }));
    }
  }, [shapes]);

  useEffect(() => {
    if (selectedShape) {
      setState((prevState) => ({
        ...prevState,
        currentNavKey: selectedShape?.key,
        currentShape: selectedShape,
      }));
    }
  }, [selectedShape]);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleCurrentNavKey = (key) => {
    setState((prevState) => ({ ...prevState, currentNavKey: key }));
  };

  const handleCurrentSize = (size) => {
    setState((prevState) => ({ ...prevState, currentSize: size }));
  };

  const renderComponentAccordingTab = useCallback(() => {
    switch (currentNavKey) {
      case "home":
        return (
          <div className="p-4">
            <p className="mb-6 sm:mb-[14px] sm:px-0 px-6 break-normal text-justify">
              Decades of experience in diamond industry and close work with
              rough growers for years, DTN understands it all when it comes to
              lab-grown diamonds Decades of experience in diamond industry and
              close work with rough growers for years, DTN understands it all
              when it comes to lab-grown diamonds Decades of experience in
              diamond industry and close work with rough growers for years, DTN
              understands it all when it comes to lab-grown diamonds Decades of
              experience in diamond industry and close work with rough growers
              for years, DTN understands it all when it comes to lab-grown
              diamonds
            </p>
          </div>
        );
      case "table-list":
        return (
          <TableList
            parentState={state}
            handleCurrentNavKey={handleCurrentNavKey}
          />
        );
      default:
        return (
          <CardList
            handleCurrentNavKey={handleCurrentNavKey}
            handleCurrentSize={handleCurrentSize}
            parentState={state}
          />
        );
    }
  }, [currentNavKey]);

  const togglePlaceOrderModal = () => {
    setState((prevState) => ({
      ...prevState,
      openPlaceOrderModal: !openPlaceOrderModal,
    }));
  };

  return (
    <>
      <div>
        <CartDrawer />

        <PlaceOrderModal
          open={openPlaceOrderModal}
          handleClose={togglePlaceOrderModal}
          formMode="add"
          item={{}}
        />

        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 xl:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-[#161b25]/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#161b25] px-6 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center justify-center font-dancingScript">
                      {/* <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your Company"
                      /> */}
                      <p class=" text-gray-100 font-dancingScript capitalize text-2xl">
                        Calibrated Shapes
                      </p>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li className="pb-12">
                          {/* <li
                            className="-mx-2 space-y-0 pb-2 flex items-center cursor-pointer"
                            onClick={() => {
                              navigate("/");
                            }}
                          >
                            <HiHome className="h-4 w-4 text-gray-300" />
                            <div className="text-gray-300 text-base pl-1">
                              Home
                            </div>
                          </li> */}

                          {!!standardData?.length && (
                            <ul role="list" className="-mx-2 space-y-1">
                              {/* <li className="border-b border-gray-800 py-1">
                                <div className="text-gray-200 text-lg">
                                  Standard
                                </div>
                              </li> */}

                              {/* {standardData?.map((item) => (
                                <li
                                  key={item.name}
                                  onClick={() => {
                                    setState((prevState) => ({
                                      ...prevState,
                                      currentShape: item,
                                      currentNavKey: item.key,
                                    }));
                                    setSidebarOpen(false);
                                  }}
                                >
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      item.key === currentNavKey ||
                                        item.key === currentShape?.key
                                        ? "bg-gray-50 text-gray-800"
                                        : "text-gray-300 hover:text-white hover:bg-gray-50",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold capitalize"
                                    )}
                                  > */}
                              {/* <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  /> */}

                              {/* <img
                                      src={`${
                                        item.icon || "/images/icons/Asscher.png"
                                      }`}
                                      className="h-6 w-6 shrink-0 invert opacity-60"
                                    /> */}

                              {/* {item?.name?.charAt(0)?.toUpperCase() +
                                      item?.name?.slice(1)?.toLowerCase()}
                                  </a>
                                </li>
                              ))} */}

                              <Disclosure as="div" defaultOpen>
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      className={classNames(
                                        // item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                        "flex items-center w-full text-left p-2 pl-0 gap-x-3 text-lg leading-6 text-gray-300 border-b border-gray-700"
                                      )}
                                    >
                                      {/* <item.icon className="h-6 w-6 shrink-0 text-gray-300" aria-hidden="true" /> */}
                                      Standard
                                      <ChevronRightIcon
                                        className={classNames(
                                          open
                                            ? "rotate-90 text-gray-500"
                                            : "text-gray-300",
                                          "ml-auto h-5 w-5 shrink-0"
                                        )}
                                        aria-hidden="true"
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel
                                      as="ul"
                                      className="mt-1 px-2"
                                    >
                                      {standardData.map((item) => (
                                        <li
                                          key={item.name}
                                          onClick={() => {
                                            dispatch(setSelectedShape(item));
                                            // setState((prevState) => ({
                                            //   ...prevState,
                                            //   currentShape: item,
                                            //   currentNavKey: item.key,
                                            // }));
                                            setSidebarOpen(false);
                                          }}
                                          className="py-0.5"
                                        >
                                          <a
                                            href={item.href}
                                            className={classNames(
                                              item.key === currentNavKey ||
                                                item.key === currentShape?.key
                                                ? "bg-gray-200 text-gray-800"
                                                : "text-gray-300 hover:text-gray-800 hover:bg-gray-200",
                                              "text-gray-400 hover:text-gray-800 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 text-sm leading-6 capitalize duration-200"
                                            )}
                                          >
                                            {/* <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        /> */}

                                            {/* <img
                                    src={`${
                                      item.icon || "/images/icons/Asscher.png"
                                    }`}
                                    className="h-6 w-6 shrink-0 invert opacity-60"
                                  /> */}
                                            {item?.name
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                              item?.name
                                                ?.slice(1)
                                                ?.toLowerCase()}
                                          </a>
                                        </li>
                                      ))}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </ul>
                          )}

                          {!!pairsData?.length && (
                            <ul role="list" className="-mx-2 space-y-1">
                              <Disclosure as="div">
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      className={classNames(
                                        // item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                        "mt-2 flex items-center w-full text-left p-2 pl-0 gap-x-3 text-lg leading-6 text-gray-300 border-b border-gray-700"
                                      )}
                                    >
                                      {/* <item.icon className="h-6 w-6 shrink-0 text-gray-300" aria-hidden="true" /> */}
                                      Side Pairs
                                      <ChevronRightIcon
                                        className={classNames(
                                          open
                                            ? "rotate-90 text-gray-500"
                                            : "text-gray-300",
                                          "ml-auto h-5 w-5 shrink-0"
                                        )}
                                        aria-hidden="true"
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel
                                      as="ul"
                                      className="mt-1 px-2"
                                    >
                                      {pairsData?.map((item) => (
                                        <li
                                          key={item.name}
                                          onClick={() => {
                                            dispatch(setSelectedShape(item));
                                            // setState((prevState) => ({
                                            //   ...prevState,
                                            //   currentShape: item,
                                            //   currentNavKey: item.key,
                                            // }));
                                            setSidebarOpen(false);
                                          }}
                                          className="py-0.5"
                                        >
                                          <a
                                            href={item.href}
                                            className={classNames(
                                              item.key === currentNavKey ||
                                                item.key === currentShape?.key
                                                ? "bg-gray-200 text-gray-800"
                                                : "text-gray-400 hover:text-gray-800 hover:bg-gray-200",
                                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 capitalize duration-200"
                                            )}
                                          >
                                            {/* <item.icon
                        className="h-6 w-6 shrink-0"
                        aria-hidden="true"
                      /> */}

                                            {/* <img
                                  src={`${
                                    item.icon || "/images/icons/Asscher.png"
                                  }`}
                                  className="h-6 w-6 shrink-0 invert opacity-60"
                                /> */}
                                            {item?.name
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                              item?.name
                                                ?.slice(1)
                                                ?.toLowerCase()}
                                          </a>
                                        </li>
                                      ))}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </ul>
                          )}
                        </li>
                       
                        <li
                          className="-mx-6 mt-auto cursor-pointer bg-gray-200 fixed bottom-0 w-full"
                          onClick={() => {
                            setSidebarOpen(false);
                            togglePlaceOrderModal();
                          }}
                        >
                          <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-800 hover:bg-gray-100">
                            <div className="flex items-center">
                              <CubeIcon className="h-4 w-4 text-gray-800 mr-2" />
                              Place Order
                            </div>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto  bg-[#161b25] px-6 ring-1 ring-white/10">
            <div className="flex h-16 shrink-0 items-center justify-center">
              {/* <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                alt="Your Company"
              /> */}

              <p class="text-gray-100 font-dancingScript capitalize text-2xl">
                Calibrated Shapes
              </p>
            </div>

            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li className="pb-12">
                  {/* <li
                    className="-mx-2 space-y-0 pb-2 flex items-center cursor-pointer"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <HiHome className="h-4 w-4 text-gray-300" />
                    <div className="text-gray-300 text-base pl-1">Home</div>
                  </li> */}

                  {!!standardData?.length && (
                    <ul role="list" className="-mx-2 space-y-1">

                      {loading ? (
                        [...Array(5).keys()]?.map((i) => {
                          return (
                            <div
                              role="status"
                              class="flex item-center justify-evenly max-w-sm rounded-lg animate-pulse dark:bg-gray-700 p-1"
                            >
                              <div class="w-5 h-4 bg-gray-100 rounded-full dark:bg-gray-700 m-2"></div>
                              <div class="h-2.5 w-full bg-gray-100 rounded-full dark:bg-gray-700 m-3"></div>
                            </div>
                          );
                        })
                      ) : (
                        <Disclosure as="div" defaultOpen>
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  // item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                  "flex items-center w-full text-left p-2 pl-0 gap-x-3 text-lg leading-6 text-gray-300 border-b border-gray-700"
                                )}
                              >
                                {/* <item.icon className="h-6 w-6 shrink-0 text-gray-300" aria-hidden="true" /> */}
                                Standard
                                <ChevronRightIcon
                                  className={classNames(
                                    open
                                      ? "rotate-90 text-gray-500"
                                      : "text-gray-300",
                                    "ml-auto h-5 w-5 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel as="ul" className="mt-1 px-2">
                                {standardData.map((item) => (
                                  <li
                                    key={item.name}
                                    onClick={() => {
                                      dispatch(setSelectedShape(item));
                                      // setState((prevState) => ({
                                      //   ...prevState,
                                      //   currentShape: item,
                                      //   currentNavKey: item.key,
                                      // }));
                                    }}
                                    className="py-0.5"
                                  >
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        item.key === currentNavKey ||
                                          item.key === currentShape?.key
                                          ? "bg-gray-200 text-gray-800"
                                          : "text-gray-300 hover:text-gray-800 hover:bg-gray-200",
                                        "text-gray-400 hover:text-gray-800 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 text-sm leading-6 capitalize duration-200"
                                      )}
                                    >
                                      {/* <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        /> */}

                                      {/* <img
                                    src={`${
                                      item.icon || "/images/icons/Asscher.png"
                                    }`}
                                    className="h-6 w-6 shrink-0 invert opacity-60"
                                  /> */}
                                      {item?.name?.charAt(0)?.toUpperCase() +
                                        item?.name?.slice(1)?.toLowerCase()}
                                    </a>
                                  </li>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </ul>
                  )}

                  {!!pairsData?.length && (
                    <ul role="list" className="-mx-2 space-y-1 mt-2">
                      {/* <li className="border-b border-gray-800 py-1 pt-4">
                        <div className="text-gray-200 text-lg">Side Pairs</div>
                      </li> */}

                      {/* {loading
                        ? [...Array(5).keys()]?.map((i) => {
                            return (
                              <div
                                role="status"
                                class="flex item-center justify-evenly max-w-sm rounded-lg animate-pulse dark:bg-gray-700 p-1"
                              >
                                <div class="w-5 h-4 bg-gray-100 rounded-full dark:bg-gray-700 m-2"></div>
                                <div class="h-2.5 w-full bg-gray-100 rounded-full dark:bg-gray-700 m-3"></div>
                              </div>
                            );
                          })
                        : pairsData?.map((item) => (
                            <li
                              key={item.name}
                              onClick={() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  currentShape: item,
                                  currentNavKey: item.key,
                                }));
                              }}
                            >
                              <a
                                href={item.href}
                                className={classNames(
                                  item.key === currentNavKey ||
                                    item.key === currentShape?.key
                                    ? "bg-gray-50 text-gray-800"
                                    : "text-gray-300 hover:text-white hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold capitalize"
                                )}
                              > */}
                      {/* <item.icon
                        className="h-6 w-6 shrink-0"
                        aria-hidden="true"
                      /> */}

                      {/* <img
                                  src={`${
                                    item.icon || "/images/icons/Asscher.png"
                                  }`}
                                  className="h-6 w-6 shrink-0 invert opacity-60"
                                /> */}
                      {/* {item?.name?.charAt(0)?.toUpperCase() +
                                  item?.name?.slice(1)?.toLowerCase()}
                              </a>
                            </li>
                          ))} */}

                      {loading ? (
                        [...Array(5).keys()]?.map((i) => {
                          return (
                            <div
                              role="status"
                              class="flex item-center justify-evenly max-w-sm rounded-lg animate-pulse dark:bg-gray-700 p-1"
                            >
                              <div class="w-5 h-4 bg-gray-100 rounded-full dark:bg-gray-700 m-2"></div>
                              <div class="h-2.5 w-full bg-gray-100 rounded-full dark:bg-gray-700 m-3"></div>
                            </div>
                          );
                        })
                      ) : (
                        <Disclosure as="div">
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  // item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                  "flex items-center w-full text-left p-2 pl-0 gap-x-3 text-lg leading-6 text-gray-300 border-b border-gray-700"
                                )}
                              >
                                {/* <item.icon className="h-6 w-6 shrink-0 text-gray-300" aria-hidden="true" /> */}
                                Side Pairs
                                <ChevronRightIcon
                                  className={classNames(
                                    open
                                      ? "rotate-90 text-gray-500"
                                      : "text-gray-300",
                                    "ml-auto h-5 w-5 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel as="ul" className="mt-1 px-2">
                                {pairsData?.map((item) => (
                                  <li
                                    key={item.name}
                                    onClick={() => {
                                      dispatch(setSelectedShape(item));
                                      // setState((prevState) => ({
                                      //   ...prevState,
                                      //   currentShape: item,
                                      //   currentNavKey: item.key,
                                      // }));
                                    }}
                                    className="py-0.5"
                                  >
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        item.key === currentNavKey ||
                                          item.key === currentShape?.key
                                          ? "bg-gray-200 text-gray-800"
                                          : "text-gray-400 hover:text-gray-800 hover:bg-gray-200",
                                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 capitalize duration-200"
                                      )}
                                    >
                                      {/* <item.icon
                        className="h-6 w-6 shrink-0"
                        aria-hidden="true"
                      /> */}

                                      {/* <img
                                  src={`${
                                    item.icon || "/images/icons/Asscher.png"
                                  }`}
                                  className="h-6 w-6 shrink-0 invert opacity-60"
                                /> */}
                                      {item?.name?.charAt(0)?.toUpperCase() +
                                        item?.name?.slice(1)?.toLowerCase()}
                                    </a>
                                  </li>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </ul>
                  )}
                </li>
                <li
                  className="-mx-6 mt-auto cursor-pointer bg-gray-200 fixed bottom-0 w-[288px]"
                  onClick={() => togglePlaceOrderModal()}
                >
                  <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-800 hover:bg-gray-100">
                    <div className="flex items-center">
                      <CubeIcon className="h-4 w-4 text-gray-800 mr-2" />
                      Place Order
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="xl:pl-72">
          {/* Sticky search header */}
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 bg-white px-4 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-[#1b2c51] xl:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-5 w-5" aria-hidden="true" />
            </button>

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 items-center justify-center">
              <header className="sm:text-center  md:text-3xl sm:text-xl text-[14px] md:my-6 text-white">
                {/* DTN
                <span className="font-dancingScript font-bold">
                  {" "}
                  Premium Quality{" "}
                </span> */}
                {/* CALIBRATED DIAMONDS */}

                <span className="font-montserrat font-[500] lg:text-[30px] sm:text-[24px] block text-[12px] text-[#161b25] sm:text-center text-left pr-16 md:pr-0">
                  <span className="font-dancingScript font-bold">
                    {" "}
                    Premium cut{" "}
                  </span>
                  Lab Grown Diamonds
                </span>

                <div className="absolute right-5 top-[22px] flex gap-4">
                  <span
                    onClick={(e) => navigate("/")}
                    className="cursor-pointer relative flex items-center"
                  >
                    <img src={homeIcon} alt="home" />{" "}
                  </span>

                  <span
                    onClick={() => handleOpenCart()}
                    className="cursor-pointer relative flex items-center"
                  >
                    <img src={cartIcon} alt="cart" />{" "}
                    <span className="flex justify-center items-center absolute -top-[45%] -right-[40%] w-4 h-4 rounded-full bg-[#161b25] text-white text-xs">
                      {cart?.length}
                    </span>
                  </span>
                </div>
              </header>
            </div>
          </div>

          <main className="bg-gray-100">
            {/* Card List */}
            <div className="w-full">
              <div className="p-4 pb-3 hidden">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    {/* <li onClick={() => navigate("/")}>
                      <div>
                        <a
                          href="#"
                          className="text-gray-300 hover:text-gray-500"
                        >
                          <HiHome
                            className="h-5 w-5 flex-shrink-0"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li> */}
                    {/* {pages.map((page) => (
                    <li key={page.name}>
                      <div className="flex items-center">
                        <ChevronRightIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-300"
                          aria-hidden="true"
                        />
                        <a
                          href={page.href}
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                          aria-current={page.current ? "page" : undefined}
                        >
                          {page.name}
                        </a>
                      </div>
                    </li>
                  ))} */}
                    {/* <li>
                      <div className="flex items-center">
                        <ChevronRightIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-300"
                          aria-hidden="true"
                        />
                        <a
                          href={"#"}
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                          aria-current={undefined}
                          onClick={()=>handleCurrentNavKey('home')}
                        >
                          Calibrated
                        </a>
                      </div>
                    </li> */}
                    {currentShape?.name && currentNavKey !== "home" && (
                      <li
                        onClick={() => handleCurrentNavKey(currentShape?.key)}
                      >
                        <div className="flex items-center">
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-300"
                            aria-hidden="true"
                          />
                          <a
                            href={"#"}
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                            aria-current={undefined}
                          >
                            {currentShape?.name}
                          </a>
                        </div>
                      </li>
                    )}

                    {currentShape?.name && currentNavKey === "table-list" && (
                      <li>
                        <div className="flex items-center">
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-300"
                            aria-hidden="true"
                          />
                          <a
                            href={"#"}
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                            aria-current={undefined}
                          >
                            List
                          </a>
                        </div>
                      </li>
                    )}
                  </ol>
                </nav>
              </div>

              {renderComponentAccordingTab()}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
