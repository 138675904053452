import React, { useEffect, useState, useCallback, useRef } from "react";
import { FiFilter } from "react-icons/fi";
import {
  getDiamondStockFilterParameter,
  getDiamondStockWithoutRateMargin,
} from "../../Services/diamondService";
import DiamondFilterDrawer from "./DiamondFilterDrawer";
import { Link, useNavigate } from "react-router-dom";
import { CiMail, CiImageOff } from "react-icons/ci";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { GrFormClose } from "react-icons/gr";
import { Md360 } from "react-icons/md";
import { IoVideocamOffOutline } from "react-icons/io5";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import {
  defaultCaratRange,
  numberRegex,
  numberRegexWithDecimals,
} from "../shared/constants";
import ModalImage from "react-modal-image";
import VideoModal from "../Admin/Modals/VideoModal";
import { Dialog, Transition } from "@headlessui/react";
import { IoPersonAdd } from "react-icons/io5";
import { Fragment } from "react";
import {
  MdOutlineClose,
  MdOutlineHideImage,
  MdOutlineVideocamOff,
} from "react-icons/md";
import { toast } from "react-hot-toast";
import Gai from "./../../assets/images/GIA.png";
import Igi from "./../../assets/images/IGI.png";
import { TbCertificate, TbCertificateOff } from "react-icons/tb";
import { getAuthState } from "../../store/Slices/AuthSlice";
import { BsWhatsapp } from "react-icons/bs";
import { createDiamonndServiceData } from "../../Services/stockRequestService";
import useApi from "../../Hooks/use-Api";
import { sortByID, tawkWidgetToggle } from "../../Utils";
import { CgPlayButtonO } from "react-icons/cg";
import DiamondListDesktopSkeleton from "./Common/skeleton/DiamondListDesktopSkeleton";
import DiamondListMobileSkeleton from "./Common/skeleton/DiamondListMobileSkeleton";
import { trackPageEvent } from "../../Hooks/use-page-tracking";
import IsLoginHeader from "./IsLoginHeader";
import login from "../../assets/images/login.svg";
import { debounce } from "@mui/material";

const filterList = ["Shape", "Carat", "Color", "Clarity", "Location"];

const Diamond = () => {
  const [stockData, setStockData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterValue, setFilterValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedTab, setSelectedTab] = useState("shape");
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [value, setValue] = useState(defaultCaratRange);
  const [videoData, setVideoData] = useState({
    videoUrl: "",
    title: "",
  });
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [locationFilter, setLocationFilter] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [data, setData] = useState({
    stEmailId: "",
    stName: "",
    stPhoneNo: "",
    certiid: "",
    stockid: "",
    message: "",
  });
  const { user, isLoggedIn } = useSelector(getAuthState);
  const selectedShape = useSelector(
    (state) => state?.selectedDiamond?.selectedShape
  );
  const trackEvent = trackPageEvent("Diamond Page");
  const User = JSON.parse(localStorage.getItem("name"));

  const tawkTo = require("tawkto-react");
  const tawkToPropertyId = "64644842ad80445890ed5f0b";
  const tawkToKey = "1h0jrkhog";
  
  useEffect(() => {
    if (window.innerWidth > 640) {
      new tawkTo(tawkToPropertyId, tawkToKey);
      tawkWidgetToggle(true);
      return () => {
        tawkWidgetToggle(false);
      };
    }
  }, [tawkTo]);

  const [fetchFilterParameters, filterParameters] = useApi(
    getDiamondStockFilterParameter
  );

  useEffect(() => {
    fetchFilterParameters();
  }, [fetchFilterParameters]);

  useEffect(() => {
    if (filterParameters) {
      setValue([filterParameters.carat[0].ID, filterParameters.carat[0].Name]);
    }
  }, [filterParameters]);

  useEffect(() => {
    if (isDataLoaded && selectedShape?.length) {
      setFilterValue({
        ...filterValue,
        shape: selectedShape?.map((i) => i?.shapeCode) || [],
      });
    }
  }, [selectedShape, isDataLoaded]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    if (data.stEmailId && data.stName && data.stPhoneNo && data.message) {
      const payload = new FormData();
      payload.append("stName", data.stName);
      payload.append("stEmail", data.stEmailId);
      payload.append("stContact", data.stPhoneNo);
      payload.append("stStoneID", data.stockid);
      payload.append("stCertiID", data.certiid);
      payload.append("stMessage", data.message);
      payload.append("flgIsVerifiedEmail", 1);

      const res = await createDiamonndServiceData(payload);
      if (res.status === 200) {
        toast.success("Request Send Successfully.");
        setOpen(false);
        setData({
          stEmailId: "",
          stName: "",
          stPhoneNo: "",
          certiid: "",
          stockid: "",
          message: "",
        });
      }
    } else {
      toast.error("All Field are Required !");
    }
  };

  const tabContent = useMemo(() => {
    return {
      shape: sortByID(filterParameters?.shape || [], "asc", "SrNo") || [],
      color: sortByID(filterParameters?.color || [], "asc", "SrNo") || [],
      clarity: sortByID(filterParameters?.clarity || [], "asc", "SrNo") || [],
    };
  }, [filterParameters]);

  const DiamondStock = useCallback(
    async (page) => {
      // debugger;
      if (page === 1) {
        setStockData([]);
      }
      const Data = new FormData();
      if (filterValue && JSON.stringify(filterValue) !== "{}") {
        Data.append(
          "ShapeId",
          filterValue?.shape ? filterValue?.shape?.join(",") : ""
        );
        Data.append(
          "ColorId",
          filterValue?.color ? filterValue?.color.join(",") : ""
        );
        Data.append(
          "ClarityId",
          filterValue?.clarity ? filterValue?.clarity.join(",") : ""
        );
        Data.append("Cut", filterValue.cut ? filterValue.cut.join(",") : "");
        Data.append("FromCarat", filterValue?.fromCarat ?? "");
        Data.append("ToCarat", filterValue?.toCarat ?? "");
        Data.append("fromPrice", filterValue?.fromPrice ?? "");
        Data.append("toPrice", filterValue?.toPrice ?? "");
        Data.append(
          "symmetry",
          filterValue?.symmetry ? filterValue?.symmetry?.join(",") : ""
        );
        Data.append(
          "polish",
          filterValue?.polish ? filterValue?.polish?.join(",") : ""
        );
        Data.append("type", filterValue?.type || "");
        Data.append("LAB", filterValue?.lab || "");
        Data.append("CERTID", filterValue?.certi || "");
        Data.append("StockID", filterValue?.StockID || "");
      }
      Data.append("Location", locationFilter ?? "");
      Data.append("UserId", user?.unUserIdentifier || "");
      Data.append("inPageNo", page);
      Data.append("inPageSize", 10);
      setLoading(true);
      try {
        const response = await getDiamondStockWithoutRateMargin(Data);
        if (response.data.success) {
          setHasMore(!(response.data.data.stock?.length < 10));
          setStockData((prev) => [...prev, ...response.data.data.stock]);
          setIsDataLoaded(true);
        }
      } catch (err) {
        console.log("Error: ", err.response);
      } finally {
        setLoading(false);
      }
    },
    [filterValue, locationFilter, user?.unUserIdentifier]
  );

  useEffect(() => {
    DiamondStock(pageNumber);
  }, [pageNumber, DiamondStock]);

  const observer = useRef();
  const dummyRef = useRef();
  const lastDiamondElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries?.[0]?.isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  const handleFilterChange = async (data, name) => {
    // setStockData([]);
    setPageNumber(1);
    // setSelectedFilter((prev) => {
    //   return {
    //     ...prev,
    //     [name]:
    //       name === "carat" ? (
    //         <span className="lowercase text-sm">
    //           {Number(data[0]).toFixed(2)} ct - {Number(data[1]).toFixed(2)} ct
    //         </span>
    //       ) : (
    //         data.Name
    //       ),
    //   };
    // });
    // setSelectedTab("");

    if (name === "carat") {
      // setValue(data);
      setFilterValue((prev) => ({
        ...prev,
        fromCarat: Number(data[0]),
        toCarat: Number(data[1]),
      }));
    } else {
      setFilterValue((prev) => {
        if (prev) {
          if (prev[name]) {
            return {
              ...prev,
              [name]:
                prev[name] && prev[name].includes(data.ID)
                  ? prev[name].filter((item) => item !== data.ID)
                  : prev[name].concat(data.ID),
            };
          } else {
            return {
              ...prev,
              [name]: [data.ID],
            };
          }
        }
        return { [name]: [data.ID] };
      });
    }
  };

  const handleRemoveFilter = (type) => {
    // setStockData([]);
    setPageNumber(1);
    const filterData = { ...filterValue };
    const selectedFilterData = { ...selectedFilter };
    delete selectedFilterData[type];
    setSelectedFilter(selectedFilterData);
    if (type === "carat") {
      setValue([filterParameters.carat[0].ID, filterParameters.carat[0].Name]);
      delete filterData.fromCarat;
      delete filterData.toCarat;
      setFilterValue(filterData);
    } else {
      delete filterData[type];
      setFilterValue(filterData);
    }
  };

  const handleVideoModalOpen = (data) => {
    setVideoData({
      videoUrl: data.VideoUrl,
      title: `Stock ID: ${data.StoneNo}`,
    });
    setVideoModalOpen(true);
  };

  const handleVideoModalClose = () => {
    setVideoModalOpen(false);
    setVideoData({
      videoUrl: "",
      title: "",
    });
  };

  const handleStockId = (e) => {
    setFilterValue((prev) => ({
      ...prev,
      StockID: e.target.value,
    }));
    setPageNumber(1);
  };

  const stockIdDebouncedOnChange = debounce(handleStockId, 700);

  return (
    <>
      <div className="flex flex-col gap-3 md:gap-4 fixed z-50 bottom-[25px] md:bottom-[95px] right-[25px] items-end 38px">
        <a
          href="https://wa.me/+85261951229"
          className="shadow-md rounded-full p-2.5 md:p-3.5 bg-[#1f2937]"
        >
          <BsWhatsapp className="text-[22px] md:text-[30px] font-bold text-white" />
        </a>
        <a
          href="mailto:info@dtndiamonds.com"
          className="shadow-md rounded-full p-2.5 md:p-3.5 bg-[#1f2937]"
        >
          <CiMail className="text-[22px] md:text-[30px] font-bold text-white" />
        </a>
      </div>
      {/* <Header /> */}
      <DiamondFilterDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setFilterValue={setFilterValue}
        filterParameters={filterParameters}
        setPageNumber={setPageNumber}
      />
      <VideoModal
        data={videoData}
        isOpen={videoModalOpen}
        onClose={handleVideoModalClose}
      />
      <div className="bg-gray-50 text-[#1b2c51]" id="diamondListDTN">
        <div className="flex flex-col justify-center flex-1 xl:max-w-[1324px] w-full mx-auto xl:px-0 px-2">
          {/* <div className="text-[22px] pt-10 mb-4 text-gray-500 font-[600] text-center">
            Site is Under Construction
          </div>
          <button type="button" className="underline" onClick={() => navigate("/")}>Go Back</button> */}
          <main className="min-h-[100vh] w-full">
            <div className="relative">
              <header className="sm:text-center text-left md:text-3xl sm:text-xl text-[14px] md:my-6 mt-6 mb-3">
                {/* <span className="font-dancingScript font-bold ">
                  {" "}
                  Premium Quality{" "}
                </span> */}
                <span className="font-dancingScript font-bold">
                  {" "}
                  Premium cut{" "}
                </span>
                Lab Grown Diamonds
              </header>
              <div className="absolute top-[50%] z-50 sm:right-4 right-2 transform -translate-y-[50%] flex items-center justify-end sm:gap-[20px] gap-[12px]">
                {User && isLoggedIn ? (
                  <IsLoginHeader />
                ) : (
                  <Link to={"/admin"} className="">
                    <img src={login} alt="login" className="h-5 w-5" />{" "}
                    {/* <IoPersonAdd className="h-[22px] w-[22px] cursor-pointer text-[#1F2937]" /> */}
                  </Link>
                )}
              </div>
            </div>
            <div className=" grid pt-2 mx-auto">
              <div className="pt-2 pb-4">
                <button
                  type="button"
                  onClick={() => navigate("/")}
                  className="flex items-center outline-none gap-2 font-Primary"
                >
                  <IoIosArrowBack /> Back
                </button>
              </div>
              <div className="flex items-center md:justify-between justify-end">
                <div className="md:mb-4 mb-2 w-full">
                  <div className="flex justify-start items-center gap-2 flex-wrap">
                    {filterList.map((filter, index) => {
                      const count = filterValue?.[filter.toLowerCase()]
                        ? filterValue?.[filter.toLowerCase()]?.length
                        : 0;
                      return (
                        <button
                          key={index}
                          className={classNames(
                            "min-w-[100px] relative font-openSans text-sm outline-none border border-gray-300 sm:hover:border-gray-700 uppercase",
                            {
                              "border-gray-700":
                                selectedTab === filter.toLowerCase(),
                            }
                          )}
                          onClick={() =>
                            setSelectedTab((prev) =>
                              prev === filter.toLowerCase()
                                ? ""
                                : filter.toLowerCase()
                            )
                          }
                        >
                          <span className="flex items-center mx-3 justify-around">
                            <span className="w-full">
                              {filter} {count ? `(${count})` : ""}{" "}
                              {filter === "Carat" && filterValue?.fromCarat
                                ? `(${filterValue?.fromCarat} - ${filterValue?.toCarat})`
                                : ""}
                            </span>
                            {filter === "Carat" ? (
                              filterValue?.fromCarat && (
                                <GrFormClose
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveFilter(filter.toLowerCase());
                                  }}
                                  className="text-[20px] "
                                />
                              )
                            ) : filter === "Location" && locationFilter ? (
                              <GrFormClose
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setLocationFilter("");
                                }}
                                className="text-[20px] "
                              />
                            ) : (
                              !!count && (
                                <GrFormClose
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveFilter(filter.toLowerCase());
                                  }}
                                  className="text-[20px] "
                                />
                              )
                            )}
                          </span>
                        </button>
                      );
                    })}

                    <input
                      type="text"
                      className="px-2 py-0.5 max-w-[100px] text-xs outline-none border border-gray-300 w-3/4 bg-transparent cursor-pointer rounded-none"
                      onChange={stockIdDebouncedOnChange}
                      placeholder="STOCK ID"
                      name="StockID"
                    />

                    <button
                      className={
                        "flex gap-1 items-center min-w-[100px] px-3 font-openSans text-sm outline-none border border-gray-300 hover:border-gray-700 uppercase"
                      }
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <FiFilter /> Multi-Selection
                    </button>
                  </div>
                  {/* <div className="flex items-center gap-2 flex-wrap">
                  </div> */}
                  {!!tabContent?.[selectedTab]?.length && (
                    <div className="flex items-center gap-2 mt-3 flex-wrap">
                      {tabContent?.[selectedTab].map((item, index) => {
                        return (
                          <button
                            key={index}
                            disabled={loading}
                            onClick={handleFilterChange.bind(
                              null,
                              item,
                              selectedTab
                            )}
                            className={classNames(
                              "min-w-[50px] text-xs px-2 outline-none border-b border-b-transparent sm:hover:border-b-gray-400",
                              {
                                "border-b-gray-400 font-medium":
                                  filterValue?.[selectedTab] &&
                                  filterValue?.[selectedTab].includes(item.ID),
                              }
                            )}
                          >
                            {item.Name}
                          </button>
                        );
                      })}
                    </div>
                  )}
                  {selectedTab === "carat" && (
                    <div className=" mt-3">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleFilterChange(value, "carat");
                        }}
                      >
                        <div className="flex gap-2 items-center ">
                          <div className="">
                            <label htmlFor="caratFrom" className="text-xs">
                              From :{" "}
                            </label>
                            <input
                              type="text"
                              className="outline-none px-2 py-0.5 w-16 border rounded text-xs"
                              id="caratFrom"
                              value={value[0]}
                              name="caratFrom"
                              onFocus={(event) => event.target.select()}
                              onChange={(e) => {
                                const { value: val } = e.target;
                                if (
                                  numberRegexWithDecimals.test(val) &&
                                  val < value[1]
                                ) {
                                  setValue([val, value[1]]);
                                }
                              }}
                            />
                          </div>
                          <div className="">
                            <label htmlFor="toFrom" className="text-xs">
                              To :{" "}
                            </label>
                            <input
                              type="text"
                              className="outline-none px-2 py-0.5 w-16 border rounded text-xs"
                              id="toFrom"
                              name="toFrom"
                              value={value[1]}
                              onFocus={(event) => event.target.select()}
                              onChange={(e) => {
                                const { value: val } = e.target;
                                if (numberRegexWithDecimals.test(val)) {
                                  setValue([value[0], val]);
                                }
                              }}
                            />
                          </div>
                          <button
                            type="submit"
                            disabled={loading}
                            className="disabled:opacity-80 disabled:cursor-not-allowed border border-gray-400 px-2 rounded text-xs bg-gray-500 text-white py-1"
                          >
                            Apply
                          </button>
                        </div>
                      </form>
                    </div>
                  )}

                  {selectedTab === "location" && (
                    <div className="flex items-center gap-2 mt-3 flex-wrap">
                      {[...new Set(stockData?.map((i) => i?.Location))]?.map(
                        (item, index) => (
                          <button
                            key={index}
                            disabled={loading}
                            onClick={() => setLocationFilter(item)}
                            className={classNames(
                              `min-w-[50px] text-xs px-2 outline-none border-b border-b-transparent sm:hover:border-b-gray-400 ${
                                locationFilter === item &&
                                "border-b-gray-400 font-medium"
                              }`
                            )}
                          >
                            {item}
                          </button>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="grid">
                {!!stockData?.length
                  ? stockData?.map((item, index) => {
                      // if (locationFilter && item?.Location !== locationFilter)
                      //   return;

                      // const certiUrl =
                      //   item.LAB === "GIA"
                      //     ? `https://www.gia.edu/report-check?reportno=${item.CERTID}`
                      //     : `https://www.igi.org/viewpdf.php?r=${item.CERTID}`;

                          const certiUrl = `https://res.cloudinary.com/dzgimbdgd/image/upload/v1715158195/Premium%20Cut%20Diamond/CERTI/${item.CERTID}.pdf`;

                      return (
                        <div
                          key={index}
                          ref={
                            index + 1 === stockData?.length
                              ? lastDiamondElementRef
                              : dummyRef
                          }
                          className="w-full py-2"
                        >
                          <div className="bg-white border-gray-200 hover:border-[#1b2c51] lg:flex hidden items-center lg:gap-4 gap-2 sm:flex-row flex-col rounded-lg overflow-hidden py-4 sm:px-6 px-4 border shadow shadow-gray-100 hover:shadow-md duration-200">
                            <div className="w-full">
                              <div className="w-full grid grid-cols-8 gap-2">
                                <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                  Shape :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.SHAPE}
                                  </span>
                                </div>
                                <div className="font-bold col-span-1 xl:text-sm text-[13px] mb-2">
                                  Carat :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.CARAT}
                                  </span>
                                </div>
                                <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                  Color :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.COLOUR}
                                  </span>
                                </div>
                                <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                  Clarity :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.CLARITY}
                                  </span>
                                </div>
                                <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-2">
                                  Cut / Polish / Sym :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.CUTPOLISHSYM}
                                  </span>
                                </div>
                                <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-2">
                                  <div className="">
                                    Cert :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">{`${item.LAB}-${item.CERTID}`}</span>
                                  </div>
                                </div>
                                {/* <div className="font-bold col-span-1 xl:text-sm text-[13px]">
                                Location :{" "}
                                <span className="font-normal xl:text-[14px] text-[13px] ">{`${item.Location || "---"
                                  }`}</span>
                              </div> */}
                              </div>
                              <div className="w-full grid grid-cols-8 gap-2">
                                <div className="font-bold xl:text-sm text-[13px] mt-2 sm:col-span-1 col-span-2">
                                  Stock ID :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.StoneNo}
                                  </span>
                                </div>
                                {/* <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                Type :{" "}
                                <span className="font-normal xl:text-[14px] text-[13px]">{item.Type}</span>
                               </div> */}
                                <div className="font-bold col-span-2 xl:text-sm text-[13px] mt-2">
                                  Measurement :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.MEASUREMENT}
                                  </span>
                                </div>
                                <div className="font-bold col-span-1 xl:text-sm text-[13px] mt-2">
                                  Loc :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {`${item.Location}`}{" "}
                                    <span
                                      className={`${
                                        item.IsAvailable === 1
                                          ? "text-black"
                                          : item.IsAvailable === 2
                                          ? "text-amber-600"
                                          : "text-amber-600"
                                      } font-medium`}
                                    >
                                      (
                                      {item.IsAvailable === 1
                                        ? "Stock"
                                        : item.IsAvailable === 2
                                        ? "Jewelry Order"
                                        : "Memo"}
                                      )
                                    </span>
                                  </span>
                                </div>
                                <div className="font-bold col-span-2 xl:text-sm text-[13px] mt-2">
                                  Rate(USD) :{" "}
                                  {item.Price > 0 ? (
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {Math.round(+item.Price)}
                                    </span>
                                  ) : (
                                    // <button
                                    //   type="button"
                                    //   className="underline font-[400]"
                                    //   onClick={() =>
                                    //     handleOpenModel(
                                    //       item.CERTID,
                                    //       item.StoneNo
                                    //     )
                                    //   }
                                    // >
                                    //   On Request
                                    // </button>
                                    <button
                                      type="button"
                                      className="font-[400] underline"
                                      onClick={() => {
                                        try {
                                          trackEvent(
                                            `_btn_request_click_`,
                                            `${item.StoneNo}/${item.CERTID}/${item.SHAPE}/${item.CARAT}`
                                          );
                                        } catch (e) {
                                          console.log("TRACK EVENT BREAK", e);
                                        }

                                        window.Tawk_API.maximize();
                                        window.Tawk_API.setAttributes(
                                          {
                                            id: "A1234",
                                            store: "Midvalley",
                                          },
                                          function (error) {
                                            console.log("ee", error);
                                          }
                                        );
                                        window.Tawk_API.addEvent(
                                          "product-add-to-cart",
                                          {
                                            StoneId: item.StoneNo,
                                            CertId: item.CERTID,
                                          },
                                          function (error) {}
                                        );
                                      }}
                                    >
                                      On Request
                                    </button>
                                  )}
                                </div>
                                <div className="font-bold xl:text-sm text-[13px] mt-2 col-span-2">
                                  <div>
                                    Amount(USD) :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.Amount > 0
                                        ? Math.round(+item.Amount)
                                        : "--"}
                                    </span>
                                  </div>
                                </div>
                                {/* <div className="min-w-fit font-bold xl:text-sm text-[13px] mr-3 mt-2 col-span-1">
                                {item.IsAvailable === "1" ? (
                                  <span className="rounded-3xl text-xs bg-green-200 text-green-600 font-normal px-2 py-1 ">
                                    Available
                                  </span>
                                ) : (
                                  <span className="rounded-3xl text-xs bg-red-200 text-red-600 font-normal px-2 py-1 ">
                                    Not available
                                  </span>
                                )}
                              </div> */}
                              </div>
                            </div>
                            {/* <div className="flex lg:gap-4 gap-2 items-center flex-row"> */}
                            <div className="grid grid-cols-3 lg:gap-4 gap-2 items-center">
                              {!!item.ImageUrl?.includes("https") ? (
                                <div className="sm:w-10 sm:h-10 w-14 h-14 bg-gray-300 rounded">
                                  <ModalImage
                                    small={item.ImageUrl}
                                    large={item.ImageUrl}
                                    className="sm:w-10 sm:h-10 w-14 h-14 rounded object-contain"
                                    alt={`Stock ID: ${item.StoneNo}`}
                                  />
                                  {/* <img
                                  src={item.ImageUrl}
                                  alt={`diamond-${index}`}
                                  className="sm:w-10 sm:h-10 w-14 h-14 rounded object-cover"
                                /> */}
                                </div>
                              ) : (
                                <div className="sm:w-10 sm:h-10 w-14 h-14 flex justify-center items-center bg-gray-200 rounded">
                                  <CiImageOff className="text-2xl text-gray-600" />
                                </div>
                              )}
                              
                              {item?.["360Url"] ||
                              !item.VideoUrl?.includes("res.cloudinary.com") ? (
                                <>
                                  <a
                                    href={item?.["360Url"] || item.VideoUrl}
                                    target="_blank"
                                  >
                                    <Tooltip title={"Click to view 360 video"}>
                                      <div className="sm:w-10 sm:h-10 w-14 h-14 cursor-pointer flex justify-center items-center bg-gray-100 rounded">
                                        <Md360 className="text-2xl text-gray-600" />
                                      </div>
                                    </Tooltip>
                                  </a>
                                </>
                              ) : !!item.VideoUrl?.includes("https") ? (
                                <Tooltip title={"Click to play video"}>
                                  <div className="sm:w-10 sm:h-10 w-14 h-14 cursor-pointer flex justify-center items-center bg-gray-100 rounded">
                                    <CgPlayButtonO
                                      className="text-2xl text-gray-600"
                                      onClick={handleVideoModalOpen.bind(
                                        null,
                                        item
                                      )}
                                    />
                                    {/* <video
                                  className="sm:w-10 sm:h-10 w-14 h-14 rounded object-cover cursor-pointer"
                                  autoPlay
                                  loop
                                  muted
                                  >
                                  <source
                                    src={item.VideoUrl}
                                    type="video/mp4"
                                    />
                                  </video> */}
                                  </div>
                                </Tooltip>
                              ) : (
                                <div className="sm:w-10 sm:h-10 w-14 h-14 flex justify-center items-center bg-gray-200 rounded">
                                  <IoVideocamOffOutline className="text-2xl text-gray-600" />
                                </div>
                              )}

                              {!!["gia", "igi"].includes(
                                item.LAB.toLowerCase()
                              ) ? (
                                <div className="sm:w-10 sm:h-10 w-14 h-14 rounded">
                                  <a
                                    href={certiUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="w-full h-full"
                                  >
                                    <img
                                      className="w-full h-full"
                                      src={
                                        item.LAB.toLowerCase() === "gia"
                                          ? Gai
                                          : Igi
                                      }
                                      alt={item.LAB.toLowerCase()}
                                    />
                                  </a>
                                </div>
                              ) : (
                                <div className="sm:w-10 sm:h-10 w-14 h-14 flex justify-center items-center bg-gray-100 rounded">
                                  <TbCertificateOff fontSize={24} className="opacity-50" />
                                </div>
                              )}
                            </div>
                            {/* <div>
                            <button className="bg-[#e2e8f0] rounded p-2.5 shadow-md">
                              <CiViewList className="text-[20px] text-[#475569]" />
                            </button>
                          </div> */}
                          </div>
                          <div className="bg-white border-gray-200 hover:border-[#1b2c51] lg:hidden flex items-center gap-2 sm:flex-row flex-col rounded-lg overflow-hidden py-4 sm:px-6 px-4 border shadow shadow-gray-100 hover:shadow-md duration-200">
                            <div className="w-full">
                              <div className="grid grid-cols-2 gap-1">
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Stock ID :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.StoneNo}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Loc :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {`${item.Location}`}{" "}
                                    <span
                                      className={`${
                                        item.IsAvailable === 1
                                          ? "text-black"
                                          : item.IsAvailable === 2
                                          ? "text-amber-600"
                                          : "text-amber-600"
                                      } font-medium`}
                                    >
                                      (
                                      {item.IsAvailable === 1
                                        ? "Stock"
                                        : item.IsAvailable === 2
                                        ? "Jewelry Order"
                                        : "Memo"}
                                      )
                                    </span>
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Shape :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.SHAPE}
                                  </span>
                                </div>
                                <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                  Carat :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.CARAT}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Color :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.COLOUR}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Clarity :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.CLARITY}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Cut/Polish/Sym :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.CUTPOLISHSYM}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Cert :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.CERTID}
                                  </span>
                                </div>

                                <div className="font-bold col-span-2 sm:text-sm text-[12px]">
                                  Measurement :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.MEASUREMENT}
                                  </span>
                                </div>
                                <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                  Rate(USD) :{" "}
                                  {item.Price > 0 ? (
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {Math.round(+item.Price)}
                                    </span>
                                  ) : (
                                    // <button
                                    //   type="button"
                                    //   className="underline font-[400]"
                                    //   onClick={() =>
                                    //     handleOpenModel(
                                    //       item.CERTID,
                                    //       item.StoneNo
                                    //     )
                                    //   }
                                    // >
                                    //   On Request
                                    // </button>
                                    <button
                                      type="button"
                                      className="font-[400] underline"
                                      onClick={() => {
                                        try {
                                          trackEvent(
                                            `_btn_request_click_`,
                                            `${item.StoneNo}/${item.CERTID}/${item.SHAPE}/${item.CARAT}`
                                          );
                                        } catch (e) {
                                          console.log("TRACK EVENT BREAK", e);
                                        }

                                        window.Tawk_API.maximize();
                                        window.Tawk_API.setAttributes(
                                          {
                                            id: "A1234",
                                            store: "Midvalley",
                                          },
                                          function (error) {
                                            console.log("ee", error);
                                          }
                                        );
                                        window.Tawk_API.addEvent(
                                          "product-add-to-cart",
                                          {
                                            StoneId: item.StoneNo,
                                            CertId: item.CERTID,
                                          },
                                          function (error) {}
                                        );
                                      }}
                                    >
                                      On Request
                                    </button>
                                  )}
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Amount(USD) :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.Amount > 0
                                      ? Math.round(+item.Amount)
                                      : "--"}
                                  </span>
                                </div>

                                {/* <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                Type :{" "}
                                <span className="font-normal">{item.Type}</span>
                              </div> */}

                                {/* <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                Rap :{" "}
                                <span className="font-normal">{item.RAP}</span>
                              </div> */}
                              </div>
                            </div>
                            <div className="flex gap-4 items-center flex-row">
                              {!!item.ImageUrl?.includes("https") ? (
                                <div className="w-10 h-10 bg-gray-300 rounded">
                                  <ModalImage
                                    small={item.ImageUrl}
                                    large={item.ImageUrl}
                                    className="w-10 h-10 rounded object-contain"
                                    alt={`Stock ID: ${item.StoneNo}`}
                                  />
                                  {/* <img
                                  src={item.ImageUrl}
                                  alt={`diamond-${index}`}
                                  className="w-10 h-10 rounded"
                                /> */}
                                </div>
                              ) : (
                                <div className="w-10 h-10 rounded flex justify-center items-center bg-gray-200">
                                  <CiImageOff className="text-2xl text-gray-600" />
                                </div>
                              )}
                              {item?.["360Url"] ||
                              !item.VideoUrl?.includes("res.cloudinary.com") ? (
                                <>
                                  <a
                                    href={item?.["360Url"] || item.VideoUrl}
                                    target="_blank"
                                  >
                                    <Tooltip title={"Click to view 360 video"}>
                                      <div className="w-10 h-10 rounded cursor-pointer flex justify-center items-center bg-gray-100">
                                        <Md360 className="text-2xl text-gray-600" />
                                      </div>
                                    </Tooltip>
                                  </a>
                                </>
                              ) : !!item.VideoUrl?.includes("https") ? (
                                <Tooltip title={"Click to play video"}>
                                  <div className="w-10 h-10 rounded cursor-pointer flex justify-center items-center bg-gray-100">
                                    <CgPlayButtonO
                                      className="text-2xl text-gray-600"
                                      onClick={handleVideoModalOpen.bind(
                                        null,
                                        item
                                      )}
                                    />
                                    {/* <video
                                  className="sm:w-10 sm:h-10 w-14 h-14 rounded object-cover cursor-pointer"
                                  autoPlay
                                  loop
                                  muted
                                  >
                                  <source
                                    src={item.VideoUrl}
                                    type="video/mp4"
                                    />
                                  </video> */}
                                  </div>
                                </Tooltip>
                              ) : (
                                <div className="w-10 h-10 rounded flex justify-center items-center bg-gray-200">
                                  <IoVideocamOffOutline className="text-2xl text-gray-600" />
                                </div>
                              )}
                              {!!["gia", "igi"].includes(
                                item.LAB.toLowerCase()
                              ) ? (
                                <div className="w-10 h-10 rounded">
                                  <a
                                    href={certiUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="w-full h-full"
                                  >
                                    <img
                                      className="w-full h-full"
                                      src={
                                        item.LAB.toLowerCase() === "gia"
                                          ? Gai
                                          : Igi
                                      }
                                      alt={item.LAB.toLowerCase()}
                                    />
                                  </a>
                                </div>
                              ) : (
                                <div className="w-10 h-10 flex justify-center items-center bg-gray-100 rounded">
                                  <TbCertificateOff fontSize={24} className="opacity-50" />
                                </div>
                              )}
                              {/* <div>
                              <button className="bg-[#e2e8f0] rounded p-2.5 shadow-md">
                                <CiViewList className="text-[20px] text-[#475569]" />
                              </button>
                            </div> */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : !loading && (
                      <div className="text-[14px] text-gray-500 font-[600] text-center">
                        No Data
                      </div>
                    )}
                {loading && (
                  <>
                    <div className="hidden lg:grid lg:gap-4 mb-4">
                      <DiamondListDesktopSkeleton />
                      {!stockData?.length && (
                        <>
                          <DiamondListDesktopSkeleton />
                          <DiamondListDesktopSkeleton />
                          <DiamondListDesktopSkeleton />
                          <DiamondListDesktopSkeleton />
                          <DiamondListDesktopSkeleton />
                          <DiamondListDesktopSkeleton />
                          <DiamondListDesktopSkeleton />
                          <DiamondListDesktopSkeleton />
                          <DiamondListDesktopSkeleton />
                        </>
                      )}
                    </div>
                    <div className="lg:hidden grid gap-4">
                      <DiamondListMobileSkeleton />
                      {!stockData?.length && <DiamondListMobileSkeleton />}
                    </div>
                  </>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="sm:flex block min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="">
                    <div className="text-center sm:mt-0 sm:text-left">
                      <div className="bg-[#f1f5f9] sm:py-4 sm:px-6 px-4 pt-5 pb-4 flex items-center justify-between">
                        <Dialog.Title
                          as="h3"
                          className="text-[20px] text-[#334155] font-[600] leading-6"
                        >
                          Diamond Request Form
                        </Dialog.Title>
                        <button
                          type="button"
                          className="text-[20px] text-[#334155] font-[600] leading-6"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        >
                          <MdOutlineClose />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 grid grid-cols-6 gap-4 sm:p-6 sm:pb-0 sm:pt-2 px-4 pt-5 pb-4">
                    <div className="col-span-6">
                      <label
                        htmlFor="stName"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="stName"
                          id="stName"
                          value={data.stName}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Enter your Name"
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <label
                        htmlFor="stEmailId"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          type="email"
                          name="stEmailId"
                          id="stEmailId"
                          value={data.stEmailId}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="you@example.com"
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <label
                        htmlFor="stPhoneNo"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Phone
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="stPhoneNo"
                          id="stPhoneNo"
                          onChange={(e) => {
                            const { value } = e.target;
                            if (numberRegex.test(value)) {
                              handleChange(e);
                            }
                          }}
                          value={data.stPhoneNo}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Enter Your Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="stPhoneNo"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Stock Id
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          disabled
                          value={data.stockid}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="stPhoneNo"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Cert Id
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          disabled
                          value={data.certiid}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <label
                        htmlFor="stPhoneNo"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Message
                      </label>
                      <div className="mt-2">
                        <textarea
                          name="message"
                          id="message"
                          cols="30"
                          rows="5"
                          maxLength={2000}
                          value={data.message}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                          placeholder="Enter Your Message"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:px-6 px-4 pb-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-[#334155] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#64748b] sm:ml-3 sm:w-auto"
                      onClick={handleSave}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Diamond;
