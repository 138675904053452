import React from "react";
import AdminModal from "../common/AdminModal";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import {
  createAttributeValueData,
  getAttributeValueByAttribute,
  updateAttributeValueData,
} from "../../../Services/attributeValueService";
import {
  fetchAttributeValueData,
  getAttributeValueState,
} from "../../../store/Slices/attributeValueSlice";
import { BsCheckCircleFill } from "react-icons/bs";
import Loading from "../../../assets/images/loading4.gif";
import axios from "axios";
import { GrFormClose } from "react-icons/gr";
import ImageUpload from "../../shared/ImageUpload";
import { everyNumber, numberRegexWithDecimals } from "../../shared/constants";

const AddAttributeValue = ({
  isAtOpen,
  setIsAtOpen,
  editId,
  setEditId,
  attributeId,
  attributeName,
  fetchData,
  selectDiamond,
  setSelectDiamond,
}) => {
  const [inputValue, setInputValue] = useState({
    stAttributeValueName: "",
    stAttributeValueAlias: "",
    dcTouch: "",
    stImageURL: "",
    stColorCode: "",
    stHTMLTag: "",
    dcFromCarat: "",
    dcToCarat: "",
    isActive: true,
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const { data } = useSelector(getAttributeValueState);
  const [filesImage, setFileImage] = useState();
  const [allImages, setAllImages] = useState([]);

  useEffect(() => {
    if (editId) {
      dispatch(fetchAttributeValueData());
    }
  }, [editId, dispatch]);

  useEffect(() => {
    if (editId) {
      const editData = data.find(
        (item) => item.unAttributeValueIdentifier === editId
      );
      if (editData) {
        setInputValue({
          stAttributeValueName: editData.stAttributeValueName,
          stAttributeValueAlias: editData.stAttributeValueAlias,
          dcTouch: editData.dcTouch,
          stImageURL: editData.stImageURL,
          stColorCode: editData.stColorCode,
          stHTMLTag: editData.stHTMLTag,
          dcFromCarat: editData.dcFromCarat,
          dcToCarat: editData.dcToCarat,
          isActive: editData.flgIsActive === "1" || editData.flgIsActive === 1 ? true : false,
        });
        setFileImage(editData.stImageURL);
      }
    }
  }, [editId, data]);

  const handleFile = (e) => {
    let file = e.target.files;
    const urlImage = URL.createObjectURL(file[0]);
    setFileImage(urlImage);
  };

  const removeImage = (i) => {
    setFileImage();
    setAllImages([]);
  };

  const handleImage = async () => {
    if (!allImages.length) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", allImages[0]);
    formData.append("upload_preset", "oocrfsyp");
    await axios
      .post("https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload", formData)
      .then((res) => {
        setInputValue((prev) => ({ ...prev, stImageURL: res.data.secure_url }));
        setIsLoading(false);
        setUploadImage(true);
      });
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: name === "flgIsActive" ? checked : value,
    }));
  };

  const handleClose = () => {
    setInputValue({
      stAttributeValueName: "",
      stAttributeValueAlias: "",
      dcTouch: "",
      stImageURL: "",
      stColorCode: "",
      stHTMLTag: "",
      dcFromCarat: "",
      dcToCarat: "",
      isActive: true,
    });
    setUploadImage(false);
    setFileImage();
    setAllImages([]);
    setEditId("");
    setSelectDiamond("");
  };

  console.log("selectDiamond", selectDiamond);

  const handleSubmit = async () => {
    if (editId) {
      const payload = {
        stAttributeValueName: inputValue.stAttributeValueName,
        stAttributeValueAlias: inputValue.stAttributeValueAlias,
        dcTouch: inputValue.dcTouch,
        stImageURL: inputValue.stImageURL,
        stColorCode: inputValue.stColorCode,
        dcFromCarat: inputValue.dcFromCarat || 0,
        dcToCarat: inputValue.dcToCarat || 0,
        flgIsActive: inputValue.isActive ? "1" : "0",
        unAttributeValueIdentifier: editId,
      };
      const res = await updateAttributeValueData(payload);

      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchAttributeValueData());
        fetchData();
        setEditId();
        setIsAtOpen(false);
      }
    } else {
      const payload = new FormData();
      payload.append("unAttributeId", attributeId);
      payload.append("stAttributeValueName", inputValue.stAttributeValueName);
      payload.append("stAttributeValueAlias", inputValue.stAttributeValueAlias);
      payload.append("dcTouch", inputValue.dcTouch);
      payload.append("stImageURL", inputValue.stImageURL);
      payload.append("stColorCode", inputValue.stColorCode);
      payload.append("stHTMLTag", inputValue.stHTMLTag);
      payload.append("dcFromCarat", inputValue.dcFromCarat || 0);
      payload.append("dcToCarat", inputValue.dcToCarat || 0);
      payload.append("flgIsActive", inputValue.isActive ? "1" : "0");
      const res = await createAttributeValueData(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    }
    handleClose();
  };

  return (
    <AdminModal
      title={
        editId
          ? `Update Attribute Value of ${attributeName}`
          : `Add Attribute Value of ${attributeName}`
      }
      onSubmit={handleSubmit}
      isOpen={isAtOpen}
      onClose={() => {
        setIsAtOpen(false);
        handleClose();
      }}
      submitButtonText={editId ? "Update" : "Save"}
    >
      <div className="mb-2">
        <label
          htmlFor="stAttributeValueName"
          className="block text-[14px] font-medium leading-6 text-gray-900"
        >
          Attribute Value Name
        </label>
        <div>
          <input
            type="text"
            name="stAttributeValueName"
            value={inputValue.stAttributeValueName}
            id="stAttributeValueName"
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            placeholder="Enter Attribute Value Name"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="flex items-center gap-[10px]">
        <div className="mb-2 w-[400px]">
          <label
            htmlFor="stAttributeValueAlias"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Attribute Value Alias
          </label>
          <div className="col-span-8 sm:col-span-4">
            <input
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
              type="text"
              name="stAttributeValueAlias"
              id="stAttributeValueAlias"
              value={inputValue.stAttributeValueAlias}
              onChange={handleChange}
              placeholder="Enter Attribute Value Alias"
            />
          </div>
        </div>
        {attributeName === "Metal" && (
          <div className="mb-2">
            <label
              htmlFor="dcTouch"
              className="block text-[14px] font-medium leading-6 text-gray-900"
            >
              Touch
            </label>
            <div className="col-span-8 sm:col-span-4">
              <input
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
                type="text"
                name="dcTouch"
                id="dcTouch"
                value={inputValue.dcTouch}
                onChange={handleChange}
                placeholder="Enter Touch"
              />
            </div>
          </div>
        )}
      </div>
      <div className="col-span-2">
        <div className="mb-3">
          <span className="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
            Image Url
          </span>
          <div className="flex items-center gap-[20px]">
            <div>
              <label
                htmlFor="formFile"
                className="border rounded-l-lg px-3 py-[9px] text-[13px]"
              >
                Choose image
              </label>
              <input
                type="text"
                disabled
                className="border rounded-r-lg py-2 px-2 text-[13px]"
                value={!!filesImage ? 1 + " " + "Files" : "Choose Images"}
              />
            </div>
            <button
              className="border rounded px-3 py-2 text-[13px]"
              onClick={handleImage}
            >
              Upload
            </button>
            {isLoading && (
              <img src={Loading} alt="loading" className="w-[40px] h-[40px]" />
            )}
            {uploadImage && <BsCheckCircleFill className="text-green-600" />}
          </div>
          <ImageUpload
            className=" hidden relative m-0 w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
            id="formFile"
            onChange={(e) => {
              handleFile(e);
              setAllImages(e.target.files);
            }}
          />
          <div className="flex flex-wrap gap-2 mt-2">
            {!!filesImage && (
              <div className="overflow-hidden relative">
                <GrFormClose
                  onClick={() => {
                    removeImage();
                  }}
                  className="absolute right-1 text-white cursor-pointer"
                />
                <img
                  className="h-20 w-20 rounded-md"
                  alt="filesImage"
                  src={filesImage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="mb-4">
        <label
          htmlFor="stImageURL"
          className="block text-[14px] font-medium leading-6 text-gray-900"
        >
          Image Url
        </label>
        <div className="flex items-center gap-[10px]">
          <input
            type="file"
            name="stImageURL"
            id="stImageURL"
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            placeholder="Enter Image Url"
            onChange={handleImage}
          />
          <button onClick={handleImage}>
            Upload
          </button>
          {isLoading && (
            <img src={Loading} alt="loading" className="w-[40px] h-[40px]" />
          )}
          {uploadImage && <BsCheckCircleFill className="text-green-600" />}
        </div>
      </div> */}
      <div className="mb-2">
        <label
          htmlFor="stColorCode"
          className="block text-[14px] font-medium leading-6 text-gray-900"
        >
          {/* Color Code */}
          Standard / Pairs
        </label>
        <div>
          <input
            type="text"
            name="stColorCode"
            value={inputValue.stColorCode}
            id="stColorCode"
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            // placeholder="Enter Color Code"
            placeholder="Enter Standard / Pairs"
            onChange={handleChange}
          />
        </div>
      </div>
      {selectDiamond === 1 && (
        <>
          <div className="mb-2 flex items-center gap-3">
            <div>
              <label
                htmlFor="from"
                className="block text-[14px] font-medium leading-6 text-gray-900"
              >
                From Carat
              </label>
              <div>
                <input
                  type="text"
                  name="dcFromCarat"
                  id="from"
                  className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
                  placeholder="Enter From Carat"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (numberRegexWithDecimals.test(value)) {
                      handleChange(e);
                    }
                  }}
                  value={inputValue.dcFromCarat || ""}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="to"
                className="block text-[14px] font-medium leading-6 text-gray-900"
              >
                To Carat
              </label>
              <div>
                <input
                  type="text"
                  name="dcToCarat"
                  id="to"
                  className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
                  placeholder="Enter To Carat"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (numberRegexWithDecimals.test(value)) {
                      handleChange(e);
                    }
                  }}
                  value={inputValue.dcToCarat || ""}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        <div className="flex items-center gap-[10px]">
          <input
            type="checkbox"
            name="isActive"
            checked={inputValue.isActive}
            onChange={handleChange}
            id="attributeCheck"
            className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
          />
          <label
            htmlFor="attributeCheck"
            className=" block text-sm font-medium leading-6 text-gray-900"
          >
            is Active
          </label>
        </div>
      </div>
    </AdminModal>
  );
};

export default AddAttributeValue;
