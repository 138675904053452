import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCategory } from "../../Services/categoryService";
import { getCategoryStylebyCategoryId, getCategoryStylesOfDesignById } from "../../Services/categoryStyleService";

export const fetchCategory = createAsyncThunk(
  "category/fetchCategory",
  async () => {
    const response = await getCategory();
    return response.data.data.categories;
  }
);

export const fetchCategoryStyleByCategoryId = createAsyncThunk(
  "categoryStyle/fetchCategoryStyleByCategoryId",
  async (id) => { 
    const response = await getCategoryStylebyCategoryId(id);
    return response.data.data.categories_style;
    // return response.data.data.categories_style;
  }
);

const initialState = {
  data: [],
  categoryStyles: [],
  categoryStylesDesign: [],
  loading: false,
  error: null,
};

const designSlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(
      fetchCategoryStyleByCategoryId.fulfilled,
      (state, action) => {
        state.categoryStyles = action.payload;
      }
    );
  },
});

export const getCategoryDataState = (state) => state.categoryData;
export const getCategoryStyleDesignDataState = (state) => state.categoryData;

export default designSlice.reducer;
