import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getCalibratedShape,
  getCalibrateStockByMM,
  getPlaceOrderList,
} from "../../Services/calibratedService";

export const fetchCalibratedData = createAsyncThunk(
  "shape/fetchData",
  async () => {
    const response = await getCalibratedShape();
    return response.data.data;
  }
);

export const fetchPlaceOrderList = createAsyncThunk(
  "calibrated/fetchPlaceOrderList",
  async () => {
    const response = await getPlaceOrderList();
    return response.data.data;
  }
);

const initialState = {
  data: [],
  loading: false,
  error: null,
  placeOrderData: [],
  placeOrderLoading: false,
  placeOrderError: null,
  placeOrderTotalCount: 0,
  selectedShape: null,
};

const calibratedSlice = createSlice({
  name: "calibrated",
  initialState,
  reducers: {
    setSelectedShape(state, action) {
      state.selectedShape = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalibratedData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCalibratedData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCalibratedData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPlaceOrderList.pending, (state, action) => {
        state.placeOrderLoading = true;
        state.placeOrderError = null;
      })
      .addCase(fetchPlaceOrderList.fulfilled, (state, action) => {
        state.placeOrderLoading = false;
        state.placeOrderData = action.payload;
        state.placeOrderTotalCount = action.payload?.total || 0;
        state.placeOrderError = null;
      })
      .addCase(fetchPlaceOrderList.rejected, (state, action) => {
        state.placeOrderLoading = false;
        state.placeOrderError = action.error.message;
      });
  },
});

export const getPlaceOrderListDataState = (state) => state.calibrated;

export const { setSelectedShape } = calibratedSlice.actions;

export default calibratedSlice.reducer;
