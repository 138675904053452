import { useDispatch, useSelector } from "react-redux";
import { getAuthState } from "../store/Slices/AuthSlice";
import useApi from "./use-Api";
import { useEffect } from "react";
import { getRolesData } from "../Services/roleService";
import { useMemo } from "react";
import { setRolePermissions } from "../store/Slices/rolePermissions";

const admin = [
  "/admin/dashboard",
  "/admin/order",
  // "/admin/orderlist",
  "/admin/product",
  "/admin/masterMenu",
  "/admin/tool",
  "/admin/menu",
  "/admin/transactionMenu",
  "/admin/reportMenu",
  "/admin/agent",
  "/admin/diamondStockList",
  "/admin/sideStonePriceList",
  "/admin/StockClarityList",
  "/admin/StockColorList",
  "/admin/StockSizeList",
  "/admin/utilityMenu",
  "/admin/addproduct",
  "/admin/addproduct",
  "/admin/invoice",
  "/admin/categorylist",
  "/admin/categoryStylelist",
  "/admin/taglist",
  "/admin/designerlist",
  "/admin/stone",
  "/admin/subMenu",
  "/admin/variation",
  "/admin/supplierlist",
  "/admin/attributelist",
  "/admin/snap&postlist",
  "/admin/agentcal",
  "/admin/attributevaluelist",
  "/admin/user-role",
  "/admin/setting",
  "/admin/diamondsrequestlist",
  "/admin/placeorderlist",
  "/admin/agentMenu",
  "/admin/catalog",
  "/admin/agent-selection",
  "/admin/readytoship",
  "/admin/addreadytoship",
  "/admin/mounting",
  "/admin/addmounting",
  "/admin/calibrated-stock"
];

const agent = ["/admin/agentMenu", "/admin/agent", "/admin/agent-selection", "/admin/catalog"];

const normalUser = ["/admin/orderlist", "/admin/profile"];

const useRolePermission = (path) => {
  const dispatch = useDispatch();

  const { hasPermission, accessableRoutes } = useSelector(s => s.rolePermissions.rolePermissions)
  const { user } = useSelector(getAuthState);
  const [fetchRoles, data] = useApi(getRolesData);

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    if (hasPermission && data?.role?.length > 0) {
      dispatch(setRolePermissions({ hasPermission, accessableRoutes, ...data }))
    }
  }, [data])


  const currentRole = useMemo(() => {
    if (data && user) {
      return (
        data.role.find((role) => role.unRoleIdentifier === user.unRoleId)
          ?.stRoleName || user.Role.toLowerCase()
      );
    }
    return "";
  }, [data, user]);

  if (user) {
    switch (user.Role.toLowerCase()) {
      case "admin":
        return [!!admin.find((item) => path.includes(item)), admin];
      case "user":
        return [!!normalUser.find((item) => path.includes(item)), normalUser];
      case "adv. user":
        return [!!normalUser.find((item) => path.includes(item)), normalUser];
      case "agent":
        return [!!agent.find((item) => path.includes(item)), agent];
      default:
        return [false, []];
    }
  }
  return [false, ["/login"]];
};

export default useRolePermission;
