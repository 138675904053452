import React, { useMemo, useState } from "react";

const Pagination = ({
  totalItems,
  activePage,
  itemsPerPage,
  setActivePage,
}) => {
  const pageNumbers = useMemo(
    () =>
      totalItems && itemsPerPage
        ? Array(Math.ceil(totalItems / itemsPerPage))
            .fill(0)
            .map((_, item) => item + 1)
        : [],
    [totalItems, itemsPerPage]
  );
  const [maxPageNumber, setMaxPageNumber] = useState([0, 4]);
  const [showFirst, setShowFirst] = useState(false);

  const indexOfLastPost = activePage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;

  const handleChangePage = (number, index) => {
    if (!index) {
      setMaxPageNumber([0, 4]);
      setShowFirst(false);
    }
    if (maxPageNumber[0] === 1) {
      setShowFirst(true);
    }
    if (!isEnd) {
      if (number === pageNumbers.length) {
        setMaxPageNumber([pageNumbers.length - 4, pageNumbers.length - 1]);
      }
      if (pageNumbers.length > 4 && index >= 3) {
        if (number !== pageNumbers.length - 2) {
          setMaxPageNumber((prev) => prev.map((item) => item + 1));
        } else {
          setMaxPageNumber([pageNumbers.length - 4, pageNumbers.length - 1]);
        }
      }
    }
    setActivePage(number);
  };

  const isEnd = useMemo(
    () =>
      [
        pageNumbers.length,
        pageNumbers.length - 1,
        pageNumbers.length - 2,
      ].includes(activePage),
    [activePage, pageNumbers]
  );

  return (
    <div className="flex w-full items-center justify-between border-t border-gray-200 bg-white dark:bg-gray-900 px-4 py-3 sm:px-4">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => setActivePage(activePage - 1)}
          className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${activePage === 1 && 'pointer-events-none opacity-50'}`}
        >
          Previous
        </button>
        <button
          onClick={() => {
            setActivePage(activePage + 1);
          }}
          className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${(activePage === pageNumbers?.length || !pageNumbers?.length) && 'pointer-events-none opacity-50'}`}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        {!!totalItems && (
          <div>
            <p className="text-sm text-gray-700">
              Showing{" "}
              <span className="font-medium">{indexOfFirstPost + 1}</span> to{" "}
              <span className="font-medium">
                {indexOfLastPost > totalItems ? totalItems : indexOfLastPost}
              </span>{" "}
              of <span className="font-medium">{totalItems}</span> results
            </p>
          </div>
        )}
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {pageNumbers.length <= 4 ? (
              pageNumbers.map((number) => (
                <button
                  key={number}
                  href="#"
                  aria-current="page"
                  onClick={() => setActivePage(number)}
                  className={`relative z-10 bg-[#e2e8f0] [&:first-child]:rounded-l-md [&:last-child]:rounded-r-md text-[#475569] inline-flex items-center border border-[#94a3b8] px-4 py-2 text-sm font-medium focus:z-20 ${
                    activePage === number && "bg-[#cbd5e1] text-[#0f172a]"
                  }`}
                >
                  {number}
                </button>
              ))
            ) : (
              <div>
                {(isEnd || showFirst) && (
                  <>
                    <button
                      onClick={() => handleChangePage(pageNumbers[0], 0)}
                      className={`relative z-10 bg-[#e2e8f0] [&:first-child]:rounded-l-md [&:last-child]:rounded-r-md text-[#475569] inline-flex items-center border border-[#94a3b8] px-4 py-2 text-sm font-medium focus:z-20 ${
                        false && "bg-[#cbd5e1] text-[#0f172a]"
                      }`}
                    >
                      {pageNumbers[0]}
                    </button>
                    <button
                      className={`relative z-10 bg-[#e2e8f0] [&:first-child]:rounded-l-md [&:last-child]:rounded-r-md text-[#475569] inline-flex items-center border border-[#94a3b8] px-4 py-2 text-sm font-medium focus:z-20`}
                    >
                      ...
                    </button>
                  </>
                )}
                {pageNumbers.slice(...maxPageNumber).map((number, index) => (
                  <button
                    key={number}
                    onClick={() => handleChangePage(number, index)}
                    className={`relative z-10 bg-[#e2e8f0] [&:first-child]:rounded-l-md [&:last-child]:rounded-r-md text-[#475569] inline-flex items-center border border-[#94a3b8] px-4 py-2 text-sm font-medium focus:z-20 ${
                      activePage === number && "bg-[#cbd5e1] text-[#0f172a]"
                    }`}
                  >
                    {number}
                  </button>
                ))}
                {!isEnd && (
                  <>
                    <button
                      className={`relative z-10 bg-[#e2e8f0] [&:first-child]:rounded-l-md [&:last-child]:rounded-r-md text-[#475569] inline-flex items-center border border-[#94a3b8] px-4 py-2 text-sm font-medium focus:z-20`}
                    >
                      ...
                    </button>
                  </>
                )}
                <button
                  onClick={() =>
                    handleChangePage(pageNumbers[pageNumbers?.length - 1], 1)
                  }
                  className={`relative z-10 bg-[#e2e8f0] [&:first-child]:rounded-l-md [&:last-child]:rounded-r-md text-[#475569] inline-flex items-center border border-[#94a3b8] px-4 py-2 text-sm font-medium focus:z-20 ${
                    false && "bg-[#cbd5e1] text-[#0f172a]"
                  }`}
                >
                  {pageNumbers[pageNumbers?.length - 1]}
                </button>
              </div>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
