import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import cartData from "./Slices/cartSlice";
import sidebar from "./Slices/sidebarStatusSlice";
import designData from "./Slices/designSlice";
import attributeData from "./Slices/attributeSlice";
import authData from "./Slices/AuthSlice";
import attributeValueData from "./Slices/attributeValueSlice";
import categoryData from "./Slices/categorySlice";
import categoryStyleData from "./Slices/categoryStyleSlice";
import designerData from "./Slices/designerSlice";
import supplierData from "./Slices/supplierSlice";
import stoneClarityData from "./Slices/stoneClaritySlice";
import stoneColorData from "./Slices/stoneColorSlice";
import stoneSizeData from "./Slices/stoneSizeSlice";
import tagsData from "./Slices/tagSlice";
import sideStonePrice from "./Slices/sideStonePriceSlice";
import snapPostData from "./Slices/snapPostSlice";
import sideStoneData from "./Slices/sideStoneSlice";
import menuData from "./Slices/menuSlice";
import selectedDiamond from "./Slices/diamondSelectSlice";
import diamondRequest from "./Slices/diamondRequestSlice";
import customizeJewelry from "./Slices/customizeJewelrySlice";
import readyToShipData from "./Slices/readyToShipSlice";
import rolePermissions from "./Slices/rolePermissions";
import calibrated from "./Slices/calibratedSlice";
import mounting from "./Slices/mountingSlice";

const persistConfig = {
  key: "cart",
  storage,
};

export const store = configureStore({
  reducer: {
    cartData: persistReducer(persistConfig, cartData),
    selectedDiamond,
    sidebar,
    designData,
    attributeData,
    authData,
    attributeValueData,
    categoryData,
    categoryStyleData,
    designerData,
    supplierData,
    stoneClarityData,
    stoneColorData,
    tagsData,
    snapPostData,
    sideStonePrice,
    stoneSizeData,
    sideStoneData,
    menuData,
    diamondRequest,
    customizeJewelry,
    readyToShipData,
    rolePermissions,
    calibrated,
    mounting
  },
  whiteList:['rolePermissions']
});
