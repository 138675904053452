import api from "./api"

const getCalibratedShape = () => {
  return api("/api/CalibrateShape", "get")
}

const getCalibrateStockByMM = (payload) => {
  return api(`/api/CalibrateStockByMM?ShapeCode=${payload.shapeCode}&&ShapeSize=${payload.shapeSize}`, "get")
}

const getCalibrateStockBySieve = (payload) => {
  return api(`/api/CalibrateStockBySieve?ShapeCode=${payload.shapeCode}&&ShapeSize=${payload.shapeSize}`, "get")
}

// Place Order

const calibratedPlaceOrder = (payload) => {
  return api("/api/insertPlaceOrder", "post", payload)
}

const getPlaceOrderList = () => {
  return api("/api/PlaceOrderList", "get")
}

export const deletePlaceOrderById = (id) =>
  api(`/api/deletePlaceOrder/${id}`, "delete");


export const AddCalibratedStockData = (data) => {
  return api(`/api/InsertStockLoose`, "post", data);
};

export {
  getCalibratedShape,
  getCalibrateStockByMM,
  getCalibrateStockBySieve,
  calibratedPlaceOrder,
  getPlaceOrderList
}