import React from "react";
import MainHeader from "../Components/LandingComponent/MainHeader";
import HomeSlider from "../Components/LandingComponent/HomeSlider";
import Aboutus from "../Components/LandingComponent/Aboutus";
import MainFooter from "../Components/LandingComponent/MainFooter";
import HomePage from "../Components/LandingComponent/HomePage";

const Home = () => {
  return (
    <>

      <HomePage />

      {/* <MainHeader />
      <HomeSlider />
      <hr className="my-2" />
      <Aboutus />
      <MainFooter /> */}
    </>
  );
};

export default Home;
