import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authRegister, getAuthState } from "../../store/Slices/AuthSlice";
import { useThunk } from "../../Hooks/use-thunk";
import Loader from "./common/Loader";
import {
  getCities,
  getCountries,
  getStates,
} from "../../Services/countryStateCityService";
import AutoCompleteWithoutTags from "./common/AutoCompleteWithoutTag";
import FormError from "../shared/FormError";
import { emailRegex } from "../shared/constants";
import { HiHome } from "react-icons/hi";

const Register = () => {
  const [formData, setFormData] = useState({
    stUserName: "",
    stPassword: "",
    c_stPassword: "",
    stFirstName: "",
    stLastName: "",
    stPhoneNumber: "",
    stEmail: "",
    stCompanyName: "",
    stAddress: "",
    stPinCode: "",
    dcStoneDisc: 0,
    dcJewelleryDisc: 0,
  });

  const [formError, setFormError] = useState({
    stUserName: "",
    stPassword: "",
    c_stPassword: "",
    stFirstName: "",
    stEmail: "",
    country: "",
  });
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [registerUser, isLoading, error] = useThunk(authRegister);
  const [contryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const { isLoggedIn } = useSelector(getAuthState);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  useEffect(() => {
    if (isLoggedIn) {
      if (state?.path) {
        navigate(state?.path);
      } else {
        navigate("/admin", { state: { prevPath: "register" } });
      }
    }
  }, [isLoggedIn, navigate, state?.path]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    const validationFields = ["stFirstName", "stUserName"];
    if (validationFields.includes(name)) {
      if (value) {
        setFormError((prev) => ({ ...prev, [name]: "" }));
      } else {
        setFormError((prev) => ({
          ...prev,
          [name]: "This field is required.",
        }));
      }
    }
    if (name === "stEmail") {
      if (value) {
        if (emailRegex.test(value)) {
          setFormError((prev) => ({ ...prev, [name]: "" }));
        } else {
          setFormError((prev) => ({
            ...prev,
            [name]: "Please Enter valid email.",
          }));
        }
      } else {
        setFormError((prev) => ({
          ...prev,
          [name]: "This field is required.",
        }));
      }
    }
    if (name === "stPassword") {
      if (value) {
        setFormError((prev) => ({
          ...prev,
          [name]: "",
        }));
        if (formData.c_stPassword) {
          if (value === formData.c_stPassword) {
            setFormError((prev) => ({ ...prev, [name]: "" }));
          } else {
            setFormError((prev) => ({
              ...prev,
              c_stPassword: "Password And confirm Password is not Matched",
            }));
          }
        }
      } else {
        setFormError((prev) => ({
          ...prev,
          [name]: "This field is required.",
        }));
      }
    }
    if (name === "c_stPassword") {
      if (value) {
        if (value === formData.stPassword) {
          setFormError((prev) => ({ ...prev, [name]: "" }));
        } else {
          setFormError((prev) => ({
            ...prev,
            [name]: "Password And confirm Password is not Matched",
          }));
        }
      } else {
        setFormError((prev) => ({
          ...prev,
          [name]: "This field is required.",
        }));
      }
    }
  };

  const fetchCountryData = async () => {
    try {
      const response = await getCountries();
      if (response.data.success) {
        setCountryData(response.data.data);
      }
    } catch (err) {
      console.log("Error: ", err.response);
    }
  };
  const fetchStateData = async (id) => {
    try {
      const response = await getStates(id);
      if (response.data.success) {
        setStateData(response.data.data);
      }
    } catch (err) {
      console.log("Error: ", err.response);
    }
  };
  const fetchCityData = async (countryId, stateId) => {
    try {
      const response = await getCities(countryId, stateId);
      if (response.data.success) {
        setCityData(response.data.data);
      }
    } catch (err) {
      console.log("Error: ", err.response);
    }
  };

  useEffect(() => {
    fetchCountryData();
  }, []);

  const checkSubmitValidation = () => {
    let isValidated = false;

    if (!formData.stUserName) {
      isValidated = false;
      setFormError((prev) => ({
        ...prev,
        stUserName: "This field is required.",
      }));
    } else {
      isValidated = true;
    }
    if (!formData.stPassword) {
      isValidated = false;
      setFormError((prev) => ({
        ...prev,
        stPassword: "This field is required.",
      }));
    } else {
      isValidated = true;
    }
    if (!formData.stFirstName) {
      isValidated = false;
      setFormError((prev) => ({
        ...prev,
        stFirstName: "This field is required.",
      }));
    } else {
      isValidated = true;
    }
    if (!formData.stEmail) {
      isValidated = false;
      setFormError((prev) => ({ ...prev, stEmail: "This field is required." }));
    } else {
      isValidated = true;
    }
    // if (!selectedCity) {
    //   isValidated = false;
    //   setFormError((prev) => ({ ...prev, city: "This field is required." }));
    // } else {
    //   isValidated = true;
    // }
    // if (!selectedCountry) {
    //   isValidated = false;
    //   setFormError((prev) => ({ ...prev, country: "This field is required." }));
    // } else {
    //   isValidated = true;
    // }
    // if (!selectedState) {
    //   isValidated = false;
    //   setFormError((prev) => ({ ...prev, state: "This field is required." }));
    // } else {
    //   isValidated = true;
    // }

    return isValidated;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.values(formError).join("")) {
      return;
    }

    if (!checkSubmitValidation()) {
      return;
    }

    const payload = new FormData();
    payload.append("stUserName", formData.stUserName);
    payload.append("stPassword", formData.stPassword);
    payload.append("c_stPassword", formData.c_stPassword);
    payload.append("stFirstName", formData.stFirstName);
    payload.append("stLastName", formData.stLastName);
    payload.append("stPhoneNumber", formData.stPhoneNumber);
    payload.append("stEmail", formData.stEmail);
    payload.append("stCompanyName", formData.stCompanyName);
    payload.append(
      "stAddress",
      formData.stAddress ? formData.stAddress : "---"
    );
    payload.append("stPinCode", formData.stPinCode ? formData.stPinCode : 0);
    payload.append("dcStoneDisc", formData.dcStoneDisc);
    payload.append("dcJewelleryDisc", formData.dcJewelleryDisc);
    payload.append("unCityId", selectedCity?.id ? selectedCity?.id : 0);
    payload.append("unStateId", selectedState?.id || 0);
    payload.append("unCountryId", selectedCountry?.id || 0);
    payload.append("socialUid", 0);
    payload.append("socialProvider", "-");
    payload.append("unRoleId", "");

    // const res = await registerUser(payload)
    registerUser(payload);
  };

  const handleChangeCountry = (val) => {
    setFormError((prev) => ({ ...prev, country: "" }));
    setSelectedCountry(val);
    fetchStateData(val.id);
  };

  const handleChangeState = (val) => {
    setFormError((prev) => ({ ...prev, state: "" }));
    setSelectedState(val);
    fetchCityData(selectedCountry?.id, val?.id);
  };

  const handleChangeCity = (val) => {
    setFormError((prev) => ({ ...prev, city: "" }));
    setSelectedCity(val);
  };

  return (
    <div className="flex h-[100vh] items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-[#f9fafb]">
      {isLoading && (
        <div className="fixed inset-0 bg-[#ffffffa9] flex justify-center items-center">
          <Loader />
        </div>
      )}
      <div className="w-full max-w-2xl">
        <div className="mb-3 text-center">
          <button
            type="button"
            onClick={() => navigate("/")}
            className="text-center bg-slate-200 p-3.5 rounded-full inline-block"
          >
            <HiHome className="text-[#295268] text-[20px]" />
          </button>
          <h2 className="mt-1 mb-2 text-center text-[22px] font-bold tracking-tight text-gray-900">
            Create to your account
          </h2>
          {error && (
            <span className="text-xs text-red-500 italic">
              Something went wrong.
            </span>
          )}
        </div>
        <form
          onSubmit={handleSubmit}
          method="post"
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-y-2 gap-x-6 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="stFirstName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First name <span className="text-red-600">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    name="stFirstName"
                    id="stFirstName"
                    value={formData.stFirstName}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm px-2 outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                  {formError.stFirstName && (
                    <FormError>{formError.stFirstName}</FormError>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="stLastName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div>
                  <input
                    type="text"
                    name="stLastName"
                    id="stLastName"
                    value={formData.stLastName}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm px-2 outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="stUserName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Username <span className="text-red-600">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    name="stUserName"
                    id="stUserName"
                    value={formData.stUserName}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm px-2 outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                  {formError.stUserName && (
                    <FormError>{formError.stUserName}</FormError>
                  )}
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="stPhoneNumber"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Phone Number <span className="text-red-600">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    name="stPhoneNumber"
                    id="stPhoneNumber"
                    value={formData.stPhoneNumber}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm px-2 outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="stCompanyName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Company Name
                </label>
                <div>
                  <input
                    id="stCompanyName"
                    name="stCompanyName"
                    type="text"
                    value={formData.stCompanyName}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm px-2 outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="stEmail"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address <span className="text-red-600">*</span>
                </label>
                <div>
                  <input
                    id="stEmail"
                    name="stEmail"
                    type="email"
                    value={formData.stEmail}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm px-2 outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                  {formError.stEmail && (
                    <FormError>{formError.stEmail}</FormError>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="stPassword"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password <span className="text-red-600">*</span>
                </label>
                <div>
                  <input
                    type="password"
                    name="stPassword"
                    id="stPassword"
                    value={formData.stPassword}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm px-2 outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                  {formError.stPassword && (
                    <FormError>{formError.stPassword}</FormError>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="c_stPassword"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm Password <span className="text-red-600">*</span>
                </label>
                <div>
                  <input
                    type="password"
                    name="c_stPassword"
                    id="c_stPassword"
                    disabled={!formData.stPassword}
                    value={formData.c_stPassword}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm px-2 outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
                {formError.c_stPassword && (
                  <FormError>{formError.c_stPassword}</FormError>
                )}
              </div>

              {/* <div className="sm:col-span-3 sm:col-start-1">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Country <span className="text-red-600">*</span>
                </label>
                <div>
                  <AutoCompleteWithoutTags
                    options={contryData}
                    setData={handleChangeCountry}
                    data={selectedCountry || {}}
                  />
                  {formError.country && (
                    <FormError>{formError.country}</FormError>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="state"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  State <span className="text-red-600">*</span>
                </label>
                <div>
                  <AutoCompleteWithoutTags
                    options={stateData}
                    setData={handleChangeState}
                    data={selectedState || {}}
                  />
                  {formError.state && <FormError>{formError.state}</FormError>}
                </div>
              </div> */}

              {/* <div className="col-span-full">
                <label
                  htmlFor="stAddress"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Address
                </label>
                <div>
                  <textarea
                    name="stAddress"
                    id="stAddress"
                    value={formData.stAddress}
                    row={3}
                    onChange={handleChange}
                    className="block w-full h-28 rounded-md border-0 py-1.5 text-gray-900 shadow-sm px-2 outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3 sm:col-start-1">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Country <span className="text-red-600">*</span>
                </label>
                <div>
                  <AutoCompleteWithoutTags
                    options={contryData}
                    setData={handleChangeCountry}
                    data={selectedCountry || {}}
                  />
                  {formError.country && (
                    <FormError>{formError.country}</FormError>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="state"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  State
                </label>
                <div>
                  <AutoCompleteWithoutTags
                    options={stateData}
                    setData={handleChangeState}
                    data={selectedState || {}}
                  />
                  {formError.state && <FormError>{formError.state}</FormError>}
                </div>
              </div>

              <div className="sm:col-span-3 sm:col-start-1">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  City
                  <span className="text-red-600">*</span>
                </label>
                <div>
                  <AutoCompleteWithoutTags
                    options={cityData}
                    setData={handleChangeCity}
                    data={selectedCity || {}}
                  />
                  {formError.city && <FormError>{formError.city}</FormError>}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="stPinCode"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  ZIP / Postal code
                </label>
                <div>
                  <input
                    type="text"
                    name="stPinCode"
                    id="stPinCode"
                    value={formData.stPinCode}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm px-2 outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
              </div> */}
            </div>
            <div className="">
              <div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-[#295268] py-2 px-4 text-sm font-medium text-white focus:outline-none mt-[14px]"
                >
                  Sign up
                </button>
              </div>
              <div className="text-center text-[14px] text-gray-500 mt-2">
                Already have an Account?{" "}
                <Link to={"/login"} className="text-[#295268] font-bold">
                  Login
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
