import React, { useEffect, useRef, useState } from "react";
import AdminModal from "../common/AdminModal";
import TextInputAdmin from "../common/TextInputAdmin";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BsCheckCircleFill } from "react-icons/bs";
import Loading from "../../../assets/images/loading4.gif";
import { updateDesignVeriaation } from "../../../Services/designService";
import { fetchDesignById } from "../../../store/Slices/designSlice";
import { useMemo } from "react";
import { GrFormClose } from "react-icons/gr";
import { numberRegex, numberRegexWithDecimals } from "../../shared/constants";
import CustomAutoComplete from "../common/CustomAutoComplete/AutoComplete";
import { getAttributeValueByAttribute } from "../../../Services/attributeValueService";

const AddVariation = ({
  isOpen,
  setIsOpen,
  item,
  index,
  designIdData,
  Description,
}) => {
  const [imageData, setImageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [mainStoneAuto, setMainStoneAuto] = useState([]);
  const [sideStoneAuto, setSideStoneAuto] = useState([]);
  const [inputValue, setInputValue] = useState({
    stSKUCode: "",
    stRef: "",
    stDescription: "",
    srno: "",
    settingPrice: "",
    dcVariationPrice: "",
    total: "",
    inMainStonesPcs: "",
    dcMainStonesCarat: "",
    dcMainStonesAmount: "",
    inSideStonesPcs: "",
    dcSideStonesCarat: "",
    dcSideStonesAmount: "",
    flgIsActive: 1,
  });
  const [filesImage, setFileImage] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [imageUploadDisable, setImageUploadDisable] = useState(false);
  const [shapeData, setShapeData] = useState([]);
  const prevImageDataRef = useRef([]);
  const dispatch = useDispatch();
  const { id } = useParams();

  console.log("designIdData", designIdData);

  const fetchMetalData = async () => {
    const resShape = await getAttributeValueByAttribute(2);
    setShapeData(resShape.data.data.attribute_value);
  };

  useEffect(() => {
    fetchMetalData();
  }, [dispatch]);

  const ShapeOption = useMemo(() => {
    return (
      shapeData.length > 0 &&
      shapeData.map((item, i) => ({
        id: item.unAttributeValueIdentifier,
        title: item.stAttributeValueName,
      }))
    );
  }, [shapeData]);

  useEffect(() => {
    if (item || index || designIdData || isOpen) {
      const mainstone = item?.stMainStones && JSON.parse(item.stMainStones);
      const mainData =
        mainstone?.length > 0 && ShapeOption?.length > 0
          ? ShapeOption.flatMap((item) => {
              let arr = [];
              mainstone?.map((ite) => {
                if (item.title === ite) {
                  arr.push(item);
                }
              });
              return arr;
            })
          : [];
      const sidestone = item.stSideStones && JSON.parse(item.stSideStones);
      const sideData =
        sidestone?.length > 0 && ShapeOption?.length > 0
          ? ShapeOption.flatMap((item) => {
              let arr = [];
              sidestone?.map((ite) => {
                if (item.title === ite) {
                  arr.push(item);
                }
              });
              return arr;
            })
          : [];
      setMainStoneAuto(mainData);
      setSideStoneAuto(sideData);
      setInputValue((prev) => ({
        ...prev,
        stSKUCode: item?.stVariation,
        stRef: item?.stRef,
        srno: index,
        settingPrice: (
                        item?.dcSalePrice !== "0.00" ? 
                        item?.dcSalePrice : 
                        designIdData?.dcSalePrice
                      ) || 0,
        stDescription: item.stDescription !== "" ? item.stDescription : `The ${
          designIdData?.categoryStyle
        } is ${item?.stVariation?.replaceAll("/", " ")}`,
        inMainStonesPcs: +item?.inMainStonesPcs || "",
        dcMainStonesCarat: +item?.dcMainStonesCarat || "",
        dcMainStonesAmount: +item?.dcMainStonesAmount || "",
        inSideStonesPcs: +item?.inSideStonesPcs || "",
        dcSideStonesCarat: +item?.dcSideStonesCarat || "",
        dcSideStonesAmount: +item?.dcSideStonesAmount || "",
        dcVariationPrice: +item?.dcVariationPrice || 0,
        flgIsActive: item.flgIsActive,
      }));
    }
  }, [item, index, designIdData, isOpen, ShapeOption]);

  useEffect(() => {
    if (item?.design_variation_images?.length) {
      setImageData(item.design_variation_images);
      setFileImage(item.design_variation_images);
      setAllImages(item.design_variation_images);
      prevImageDataRef.current = item.design_variation_images;
    }
  }, [item]);

  const totalVal = useMemo(() => {
    if (
      inputValue.settingPrice &&
      (inputValue.dcVariationPrice || !inputValue.dcVariationPrice)
    ) {
      return +inputValue.settingPrice + +inputValue.dcVariationPrice;
    } else {
      return 0;
    }
  }, [inputValue]);

  const handleClose = () => {
    setInputValue({
      stSKUCode: "",
      stRef: "",
      stDescription: "",
      srno: "",
      settingPrice: "",
      dcVariationPrice: "",
      total: "",
      inMainStonesPcs: "",
      dcMainStonesCarat: "",
      dcMainStonesAmount: "",
      inSideStonesPcs: "",
      dcSideStonesCarat: "",
      dcSideStonesAmount: "",
      flgIsActive: 1,
    });
    setImageData([]);
    setFileImage([]);
    setAllImages([]);
    setMainStoneAuto([]);
    setSideStoneAuto([]);
    prevImageDataRef.current = [];
    setUploadImage(false);
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const checkData = checked ? 1 : 0;
    setInputValue((prev) => ({
      ...prev,
      [name]: name === "flgIsActive" ? checkData : value,
    }));
  };

  const handaleImageChanges = async () => {
    setImageData([]);
    setIsLoading(true);
    const Images = Array.from(allImages);
    Images?.length &&
      Images?.forEach(async (element, i) => {
        const formData = new FormData();
        formData.append("file", element);
        formData.append("upload_preset", "oocrfsyp");
        await axios
          .post(
            "https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload",
            formData
          )
          .then((res) => {
            setImageData((prev) => [...prev, res.data.secure_url]);
            setUploadImage(true);
          });
      });
    setIsLoading(false);
    setImageUploadDisable(false);
  };

  const handleFile = (e) => {
    setImageUploadDisable(true)
    let file = e.target.files;
    const arr = [];
    for (let i = 0; i < file.length; i++) {
      const urlImage = URL.createObjectURL(file[i]);
      arr.push(urlImage);
    }
    setFileImage((prev) => [...prev, ...arr]);
  };

  const removeImage = (i) => {
    setFileImage(filesImage.filter((x, inx) => inx !== i));
    setAllImages(Array.from(allImages).filter((x, inx) => inx !== i));
    setImageData(imageData.filter((x, inx) => inx !== i))
    setImageUploadDisable(false)
  };

  const handleSubmit = async () => {
    const DataPayload = {
      unDesignVariationIdentifier: item.unDesignVariationIdentifier,
      dcSalePrice: inputValue.settingPrice,
      stRef: inputValue.stRef,
      dcVariationPrice: inputValue.dcVariationPrice,
      stVariation: inputValue.stSKUCode,
      stDescription: inputValue.stDescription,
      images: JSON.stringify(imageData),
      stMainStones: JSON.stringify(mainStoneAuto.map((item) => item.title)),
      inMainStonesPcs: inputValue?.inMainStonesPcs,
      dcMainStonesCarat: inputValue?.dcMainStonesCarat,
      dcMainStonesAmount: inputValue?.dcMainStonesAmount,
      stSideStones: JSON.stringify(sideStoneAuto.map((item) => item.title)),
      inSideStonesPcs: inputValue?.inSideStonesPcs,
      dcSideStonesCarat: inputValue?.dcSideStonesCarat,
      dcSideStonesAmount: inputValue?.dcSideStonesAmount,
      flgIsActive: inputValue.flgIsActive,
    };
    const res = await updateDesignVeriaation(DataPayload);
    if (res.status === 200) {
      dispatch(fetchDesignById(id));
      toast.success(res.data.message);
      setIsOpen(false);
    }
    handleClose();
  };

  // useEffect(() => {
  //   setImageUploadDisable(
  //     JSON.stringify(prevImageDataRef.current) !== JSON.stringify(allImages)
  //   );
  // }, [allImages]);

  return (
    <AdminModal
      title={item ? "Update Variation" : "Add Variation"}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        handleClose();
      }}
      disableSubmit={imageUploadDisable}
      submitButtonText={item ? "Update" : "Save"}
      width="w-full sm:max-w-2xl max-h-[92vh]"
    >
      <div className="grid sm:grid-cols-10 text-[12px] gap-y-1 gap-x-2 sm:text-[14px] leading-[20px] font-[500]">
        <div className="sm:col-span-2">
          <label
            htmlFor="srno"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Sr. No.
          </label>
          <div>
            <TextInputAdmin
              name="srno"
              type="text"
              disable={true}
              id="srno"
              onChange={handleChange}
              value={inputValue.srno}
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="srno"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Ref. No.
          </label>
          <div>
            <TextInputAdmin
              name="srno"
              type="text"
              id="stRef"
              value={inputValue.stRef}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="sm:col-span-6">
          <label
            htmlFor="stSKUCode"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            SKU Code
          </label>
          <div>
            <TextInputAdmin
              name="stSKUCode"
              type="text"
              disable={true}
              id="stSKUCode"
              value={inputValue.stSKUCode}
            />
          </div>
        </div>
        <div className="sm:col-span-10">
          <label
            htmlFor="stDescription"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Description
          </label>
          <div>
            <TextInputAdmin
              name="stDescription"
              type="text"
              id="stDescription"
              // disable={true}
              onChange={handleChange}
              value={inputValue.stDescription}
            />
          </div>
        </div>
        <div className="col-span-full pt-2 pb-1">
          <div className="col-span-4 border border-slate-700 h-[1px]"></div>
          {/* <div className="col-span-2 text-center text-[13px] py-[1px]">
            Stone Details
          </div>
          <div className="col-span-4 border border-slate-700 h-[1px]"></div> */}
        </div>
        <div className="sm:col-span-4">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Main Stone Shape
          </label>
          {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
          <CustomAutoComplete
            onChange={(data) => setMainStoneAuto(data)}
            defValue={mainStoneAuto}
            options={ShapeOption}
          />
        </div>
        <div className="sm:col-span-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Pcs.
          </label>
          {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
          <TextInputAdmin
            type="text"
            value={inputValue.inMainStonesPcs}
            onChange={(e) => {
              const { value } = e.target;
              if (numberRegexWithDecimals.test(value)) {
                handleChange(e);
              }
            }}
            name="inMainStonesPcs"
            className="rounded py-[5px]"
          />
        </div>
        <div className="sm:col-span-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Carat
          </label>
          {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
          <TextInputAdmin
            type="text"
            value={inputValue.dcMainStonesCarat}
            onChange={(e) => {
              const { value } = e.target;
              if (numberRegexWithDecimals.test(value)) {
                handleChange(e);
              }
            }}
            name="dcMainStonesCarat"
            className="rounded py-[5px]"
          />
        </div>
        <div className="sm:col-span-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Amount
          </label>
          {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
          <TextInputAdmin
            type="text"
            value={inputValue.dcMainStonesAmount}
            onChange={(e) => {
              const { value } = e.target;
              if (numberRegexWithDecimals.test(value)) {
                handleChange(e);
              }
            }}
            name="dcMainStonesAmount"
            className="rounded py-[5px]"
          />
        </div>
        <div className="sm:col-span-4">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Side Stone Shape
          </label>
          {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
          <CustomAutoComplete
            onChange={(data) => setSideStoneAuto(data)}
            defValue={sideStoneAuto}
            options={ShapeOption}
          />
        </div>
        <div className="sm:col-span-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Pcs.
          </label>
          {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
          <TextInputAdmin
            type="text"
            value={inputValue.inSideStonesPcs}
            onChange={(e) => {
              const { value } = e.target;
              if (numberRegexWithDecimals.test(value)) {
                handleChange(e);
              }
            }}
            name="inSideStonesPcs"
            className="rounded py-[5px]"
          />
        </div>
        <div className="sm:col-span-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Carat
          </label>
          {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
          <TextInputAdmin
            type="text"
            value={inputValue.dcSideStonesCarat}
            onChange={(e) => {
              const { value } = e.target;
              if (numberRegexWithDecimals.test(value)) {
                handleChange(e);
              }
            }}
            name="dcSideStonesCarat"
            className="rounded py-[5px]"
          />
        </div>
        <div className="sm:col-span-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Amount
          </label>
          {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
          <TextInputAdmin
            type="text"
            value={inputValue.dcSideStonesAmount}
            onChange={(e) => {
              const { value } = e.target;
              if (numberRegexWithDecimals.test(value)) {
                handleChange(e);
              }
            }}
            name="dcSideStonesAmount"
            className="rounded py-[5px]"
          />
        </div>
        <div className="col-span-full pt-2 pb-1">
          <div className="col-span-4 border border-slate-700 h-[1px]"></div>
          {/* <div className="col-span-2 text-center text-[13px] py-[1px]">
            Pricing Details
          </div>
          <div className="col-span-4 border border-slate-700 h-[1px]"></div> */}
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="settingPrice"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Setting Price
          </label>
          <div>
            <TextInputAdmin
              name="settingPrice"
              type="text"
              // disable={true}
              id="settingPrice"
              onChange={handleChange}
              value={inputValue.settingPrice}
            />
          </div>
        </div>
        <div className="sm:col-span-4">
          <label
            htmlFor="dcVariationPrice"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Variation
          </label>
          <div>
            <TextInputAdmin
              name="dcVariationPrice"
              type="text"
              id="dcVariationPrice"
              onChange={(e) => {
                if (numberRegex.test(e.target.value)) {
                  handleChange(e);
                }
              }}
              value={inputValue.dcVariationPrice}
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="total"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Total
          </label>
          <div>
            <TextInputAdmin
              name="total"
              disable={true}
              type="text"
              id="total"
              onChange={handleChange}
              value={totalVal}
            />
          </div>
        </div>
        <div className="sm:col-span-10">
          <span className="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
            Images
          </span>
          <div className="flex items-center gap-[20px]">
            <div>
              <label
                htmlFor="formFile"
                className="border rounded-l-lg px-3 py-[9px] text-[13px]"
              >
                Choose image
              </label>
              <input
                type="text"
                disabled
                className="border rounded-r-lg py-2 px-2"
                value={
                  !allImages?.length
                    ? "Choose Images"
                    : `${allImages?.length} "Files"`
                }
              />
            </div>
            <input
              className=" hidden relative m-0 w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
              type="file"
              id="formFile"
              onChange={(e) => {
                handleFile(e);
                setAllImages((prev) => [...prev, ...e.target.files]);
              }}
              multiple
            />
            <button
              className="border rounded px-3 py-2 text-[13px] disabled:opacity-70"
              onClick={handaleImageChanges}
              disabled={!imageUploadDisable}
            >
              Upload
            </button>
            {isLoading && (
              <img src={Loading} alt="loading" className="w-[40px] h-[40px]" />
            )}
            {uploadImage && <BsCheckCircleFill className="text-green-600" />}
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {filesImage.map((file, key) => {
              return (
                <div key={key} className="overflow-hidden relative">
                  <GrFormClose
                    onClick={() => {
                      removeImage(key);
                    }}
                    className="absolute right-1 text-white cursor-pointer"
                  />
                  <img
                    className="h-20 w-20 rounded-md"
                    src={file}
                    alt={`dta-${key + 1}`}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-span-3">
          <div className="flex items-center gap-[10px]">
            <input
              type="checkbox"
              name="flgIsActive"
              checked={inputValue.flgIsActive}
              onChange={handleChange}
              id="flgIsActive"
              className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
            />
            <label
              htmlFor="flgIsActive"
              className=" block text-sm font-medium leading-6 text-gray-900"
            >
              is Active
            </label>
          </div>
        </div>
      </div>
    </AdminModal>
  );
};

export default AddVariation;
