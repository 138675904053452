import { Listbox, Menu, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { useRef } from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { BsPlusLg } from "react-icons/bs";
import UploadDiamondStock from "./Modals/UploadDiamondStock";
import {
  DeleteStock,
  getDiamondStock,
  getDiamondStockFilterParameter,
} from "../../Services/diamondService";
import Loader from "./common/Loader";
import { TbCertificate } from "react-icons/tb";
import ModalImage from "react-modal-image";
import Gai from "./../../assets/images/GIA.png";
import Igi from "./../../assets/images/IGI.png";
import {
  defaultCaratRange,
  numberRegexWithDecimals,
} from "../shared/constants";
import { useMemo } from "react";
import { useCallback } from "react";
import classNames from "classnames";
import { GrFormClose } from "react-icons/gr";
import { CgPlayButtonO, CgUnavailable } from "react-icons/cg";
import { TiArrowBack } from "react-icons/ti";
import { sortByID } from "../../Utils";
import useApi from "../../Hooks/use-Api";
import DiamondFilterDrawer from "../LandingComponent/DiamondFilterDrawer";
import VideoModal from "./Modals/VideoModal";
import Tooltip from "@mui/material/Tooltip";
import { GoKebabVertical } from "react-icons/go";
import { MdOutlineHideImage, MdOutlineVideocamOff } from "react-icons/md";
import EditDiamond from "./Modals/EditDiamond";
import UploadStockImage from "./UploadStockImage";
import { Link, useNavigate } from "react-router-dom";
import AddDiamondStock from "./AddDiamondStock";
import { debounce } from "@mui/material";
import * as XLSX from "xlsx";

const actionOptions = [
  { id: 1, title: "Download Stock Excel" },
  { id: 2, title: "Upload Stock Excel" },
  { id: 3, title: "Mistake Stock Excel" },
];

const filterList = ["Shape", "Carat", "Color", "Clarity", "Location"];

const headerList = [
  "STOCKNO",
  "LAB",
  "CERTIID",
  "SHAPE",
  "CARAT",
  "COLOUR",
  "CLARITY",
  "CUT",
  "POLISH",
  "SYM",
  "TYPE",
  "LENGTH",
  "WIDTH",
  "DEPTH",
  "DEPTHPER",
  "TABLEPER",
  "COSTRATE",
  "COSTAMOUNT",
  "RAP",
  "BACK",
  "RATE",
  "AMOUNT",
  "SHOWRATE",
  "ORIGIN",
  "LOCATION",
  "IMAGEURL",
  "VIDEOURL",
  "360 VIDEO",
  "REMARK",
];

const DiamondStockList = () => {
  const navigate = useNavigate();
  const [editData, setEditData] = useState("");
  const [selectedAction, setSelectedAction] = useState(null);
  const [uploadStock, setUploadStock] = useState(false);
  const [stockData, setStockData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterValue, setFilterValue] = useState(null);
  const [locationFilter, setLocationFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [value, setValue] = useState(defaultCaratRange);
  const [videoData, setVideoData] = useState({
    videoUrl: "",
    title: "",
  });
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [fetchFilterParameters, filterParameters] = useApi(
    getDiamondStockFilterParameter
  );

  const [state, setState] = useState({
    openModal: false,
  });

  let { openModal } = state;

  useEffect(() => {
    if (filterParameters) {
      setValue([
        +filterParameters.carat[0].ID,
        +filterParameters.carat[0].Name,
      ]);
    }
  }, [filterParameters]);

  useEffect(() => {
    fetchFilterParameters();
  }, [fetchFilterParameters]);

  const toggleModal = () => {
    setState((prevState) => ({
      ...prevState,
      openModal: !openModal,
    }));
  };

  const tabContent = useMemo(() => {
    return {
      shape: sortByID(filterParameters?.shape || [], "asc", "SrNo") || [],
      color: sortByID(filterParameters?.color || [], "asc", "SrNo") || [],
      clarity: sortByID(filterParameters?.clarity || [], "asc", "SrNo") || [],
    };
  }, [filterParameters]);

  const DiamondStock = useCallback(
    async (page, limit) => {
      if (page === 1) {
        setStockData([]);
      }
      const Data = new FormData();
      if (filterValue && JSON.stringify(filterValue) !== "{}") {
        Data.append(
          "ShapeId",
          filterValue?.shape ? filterValue?.shape?.join(",") : ""
        );
        Data.append(
          "ColorId",
          filterValue?.color ? filterValue?.color.join(",") : ""
        );
        Data.append(
          "ClarityId",
          filterValue?.clarity ? filterValue?.clarity.join(",") : ""
        );
        Data.append("Cut", filterValue.cut || "");
        Data.append("FromCarat", filterValue?.fromCarat ?? "");
        Data.append("ToCarat", filterValue?.toCarat ?? "");
        Data.append("fromPrice", filterValue?.fromPrice ?? "");
        Data.append("toPrice", filterValue?.toPrice ?? "");
        Data.append("symmetry", filterValue?.symmetry || "");
        Data.append("polish", filterValue?.polish || "");
        Data.append("type", filterValue?.type || "");
        Data.append("LAB", filterValue.lab || "");
        Data.append("CERTID", filterValue.certi || "");
        Data.append("StockID", filterValue?.StockID || "");
      }
      Data.append("Location", locationFilter ?? "");
      Data.append("inPageNo", page);
      Data.append("inPageSize", limit || 10);
      setLoading(true);
      const response = await getDiamondStock(Data);
      setLoading(false);
      if (response.data.success) {
        setHasMore(!(response.data.data.stock?.length < 10));
        setStockData((prev) => [...prev, ...response.data.data.stock]);
      }
    },
    [filterValue, locationFilter]
  );

  useEffect(() => {
    DiamondStock(pageNumber);
  }, [pageNumber, DiamondStock]);

  const observer = useRef();
  const dummyRef = useRef();
  const lastDiamondElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries?.[0]?.isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleFilterChange = async (data, name) => {
    // setStockData([]);
    setPageNumber(1);
    // setSelectedFilter((prev) => {
    //   return {
    //     ...prev,
    //     [name]:
    //       name === "carat" ? (
    //         <span className="lowercase text-sm">
    //           {Number(data[0]).toFixed(2)} ct - {Number(data[1]).toFixed(2)} ct
    //         </span>
    //       ) : (
    //         data.Name
    //       ),
    //   };
    // });
    // setSelectedTab("");

    if (name === "carat") {
      setValue(data);
      setFilterValue((prev) => ({
        ...prev,
        fromCarat: Number(data[0]),
        toCarat: Number(data[1]),
      }));
    } else {
      setFilterValue((prev) => {
        if (prev) {
          if (prev[name]) {
            return {
              ...prev,
              [name]:
                prev[name] && prev[name].includes(data.ID)
                  ? prev[name].filter((item) => item !== data.ID)
                  : prev[name].concat(data.ID),
            };
          } else {
            return {
              ...prev,
              [name]: [data.ID],
            };
          }
        }
        return { [name]: [data.ID] };
      });
    }
  };

  const handleRemoveFilter = (type) => {
    // setStockData([]);
    setPageNumber(1);
    const filterData = { ...filterValue };
    const selectedFilterData = { ...selectedFilter };
    delete selectedFilterData[type];
    setSelectedFilter(selectedFilterData);
    if (type === "carat") {
      setValue([filterParameters.carat[0].ID, filterParameters.carat[0].Name]);
      delete filterData.fromCarat;
      delete filterData.toCarat;
      setFilterValue(filterData);
    } else {
      delete filterData[type];
      setFilterValue(filterData);
    }
  };

  const handleVideoModalOpen = (data) => {
    setVideoData({
      videoUrl: data.VideoUrl,
      title: `Stock ID: ${data.StoneNo}`,
    });
    setVideoModalOpen(true);
  };

  const handleVideoModalClose = () => {
    setVideoModalOpen(false);
    setVideoData({
      videoUrl: "",
      title: "",
    });
  };

  const downloadExcel = (dataList = [], fileName = "Stock") => {
    // Convert JSON to worksheet
    const ws = XLSX.utils.json_to_sheet([headerList, ...dataList], {
      skipHeader: true,
    });

    // Create a workbook with the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "All");

    // Save the workbook as a blob
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const downloadStockSampleFile = () => {
    const dataList = [
      [
        1,
        "IGI",
        459106084,
        "ROUND",
        0.3,
        "E",
        "SI1",
        "ID",
        "EX",
        "EX",
        "HPHT",
        4.3,
        4.33,
        2.64,
        61.2,
        56.0,
        ,
        ,
        1700,
        -89.0,
        187,
        56.1,
        ,
        ,
        "HK",
        "https://res.cloudinary.com/dzgimbdgd/image/upload/v1700549942/CERT%20STOCK/S1/S001_RND_0.30ESI1_6084_j66t2w.jpg",
      ],
    ];

    downloadExcel(dataList, "StockSampleFile");
  };

  const downloadStockExcel = async () => {
    const Data = new FormData();
    Data.append("inPageNo", 1);
    Data.append("inPageSize", 10000);

    const response = await getDiamondStock(Data);
    if (response.data.success) {
      const dataList = response.data.data.stock?.map((item) => [
        item?.StoneNo,
        item?.LAB,
        item?.CERTID,
        item?.SHAPE,
        item?.CARAT,
        item?.COLOUR,
        item?.CLARITY,
        item?.CUTPOLISHSYM?.split(" ")?.[0],
        item?.CUTPOLISHSYM?.split(" ")?.[1],
        item?.CUTPOLISHSYM?.split(" ")?.[2],
        "",
        item?.MEASUREMENT?.split("x")?.[0],
        item?.MEASUREMENT?.split("x")?.[1],
        item?.MEASUREMENT?.split("x")?.[2],
        "",
        "",
        "",
        "",
        item?.RAP,
        item?.Back,
        item?.Price,
        item?.Amount,
        "",
        "",
        item?.Location,
        "",
        item?.ImageUrl,
        item?.VideoUrl,
        "",
      ]);

      downloadExcel(dataList, "StockData");
    }
  };

  useEffect(() => {
    if (selectedAction?.title === "Upload Stock Excel") {
      setUploadStock(true);
    }

    if (selectedAction?.title === "Download Stock Excel") {
      downloadStockExcel();
    }
  }, [selectedAction]);

  useEffect(() => {
    if (!uploadStock) {
      setSelectedAction(null);
    }
  }, [uploadStock]);

  const handleEdit = (id) => {
    setEditData(id);
    // setEditOpen(true);
    toggleModal();
  };

  const handleDelete = async (id) => {
    try {
      const res = await DeleteStock(id);
      if (res.status === 200) {
        toast.success("Stock Deleted.");
        DiamondStock(pageNumber);
      }
    } catch (error) {
      console.log("Error:", error.response);
    }
  };

  const handleStockId = (e) => {
    setFilterValue((prev) => ({
      ...prev,
      StockID: e.target.value,
    }));
    setPageNumber(1);
  };

  const stockIdDebouncedOnChange = debounce(handleStockId, 700);

  return (
    <>
      <AddDiamondStock
        open={openModal}
        handleClose={toggleModal}
        DiamondStock={DiamondStock}
        pageNumber={pageNumber}
        formMode={editData ? "edit" : "add"}
        item={editData}
      />

      <div className="flex flex-col flex-1 w-full h-[90vh] bg-gray-100 pt-5">
        <UploadDiamondStock
          isOpen={uploadStock}
          setIsOpen={setUploadStock}
          downloadStockSampleFile={downloadStockSampleFile}
        />
        <DiamondFilterDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setFilterValue={setFilterValue}
          filterParameters={filterParameters}
          setPageNumber={setPageNumber}
        />
        <EditDiamond
          isOpen={editOpen}
          setIsOpen={setEditOpen}
          editData={editData}
          setEditData={setEditData}
          fetchUpdate={DiamondStock.bind(null, 1)}
          setStockData={setStockData}
        />
        <VideoModal
          data={videoData}
          isOpen={videoModalOpen}
          onClose={handleVideoModalClose}
        />
        <UploadStockImage
          isOpen={uploadOpen}
          setIsOpen={setUploadOpen}
          stockData={stockData}
          DiamondStock={DiamondStock}
          dummyRef={dummyRef}
          lastDiamondElementRef={lastDiamondElementRef}
          loading={loading}
          Igi={Igi}
          Gai={Gai}
          fetchUpdate={DiamondStock.bind(null, 1)}
          handleVideoModalOpen={handleVideoModalOpen}
        />
        <main className="h-full overflow-y-auto">
          <div className="mx-auto px-6 pb-6">
            <div className="flex items-center justify-between gap-[10px]">
              <Link
                to={"/admin/transactionMenu"}
                className="rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
              >
                <TiArrowBack className="text-[18px]" /> Back
              </Link>
              <button
                onClick={() => {
                  setEditData("");
                  toggleModal();
                }}
                className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
              >
                <BsPlusLg className="text-[10px]" /> Add
              </button>
            </div>

            <div className="flex items-center justify-between lg:flex-row flex-col lg:gap-0 gap-4 mt-4">
              <div className="flex flex-wrap items-center  gap-4 order-1">
                {/* <button
                onClick={() => setUploadOpen(!uploadOpen)}
                className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[10px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-between items-center gap-1 hover:bg-[#f8fafc] "
              >
                Upload Image/Video
              </button> */}
                <Listbox value={selectedAction} onChange={setSelectedAction}>
                  <div className="relative">
                    <Listbox.Button className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-between items-center gap-1 hover:bg-[#f8fafc] ">
                      <span className="block truncate">Action Dropdown</span>
                      <span className="pointer-events-none  flex items-center">
                        <ChevronUpDownIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {actionOptions?.length &&
                          actionOptions?.map((item, index) => (
                            <Listbox.Option
                              key={index}
                              className={({ active }) =>
                                `relative cursor-pointer select-none py-2 pl-4 pr-4 ${
                                  active
                                    ? "bg-[#edf4f8] text-gray-900"
                                    : "text-gray-900"
                                }`
                              }
                              value={item}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {item?.title}
                                  </span>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
              <div>
                <div className="flex justify-around sm:justify-start items-center gap-2 flex-wrap">
                  {filterList.map((filter, index) => {
                    const count = filterValue?.[filter.toLowerCase()]
                      ? filterValue?.[filter.toLowerCase()]?.length
                      : 0;
                    return (
                      <button
                        key={index}
                        className={classNames(
                          "min-w-[100px] relative font-openSans text-sm outline-none border border-gray-300 sm:hover:border-gray-700 uppercase",
                          {
                            "border-gray-700":
                              selectedTab === filter.toLowerCase(),
                          }
                        )}
                        onClick={() =>
                          setSelectedTab((prev) =>
                            prev === filter.toLowerCase()
                              ? ""
                              : filter.toLowerCase()
                          )
                        }
                      >
                        <span className="flex items-center mx-3 justify-around">
                          <span className="w-full">
                            {filter} {count ? `(${count})` : ""}{" "}
                            {filter === "Carat" && filterValue?.fromCarat
                              ? `(${filterValue?.fromCarat} - ${filterValue?.toCarat})`
                              : ""}
                          </span>
                          {filter === "Carat" ? (
                            filterValue?.fromCarat && (
                              <GrFormClose
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveFilter(filter.toLowerCase());
                                }}
                                className="text-[20px] "
                              />
                            )
                          ) : filter === "Location" && locationFilter ? (
                            <GrFormClose
                              onClick={(e) => {
                                e.stopPropagation();
                                setLocationFilter("");
                              }}
                              className="text-[20px] "
                            />
                          ) : (
                            !!count && (
                              <GrFormClose
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveFilter(filter.toLowerCase());
                                }}
                                className="text-[20px] "
                              />
                            )
                          )}
                        </span>
                      </button>
                    );
                  })}

                  <input
                    type="text"
                    className="px-2 py-0.5 max-w-[100px] text-xs outline-none border border-gray-300 w-3/4 bg-transparent cursor-pointer rounded-none"
                    onChange={stockIdDebouncedOnChange}
                    placeholder="STOCK ID"
                    name="StockID"
                  />

                  <button
                    className={
                      "flex gap-1 items-center min-w-[100px] px-3 font-openSans text-sm outline-none border border-gray-300 hover:border-gray-700 uppercase"
                    }
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <FiFilter /> Advanced Filter
                  </button>
                </div>
                {/* <div className="flex items-center gap-2 flex-wrap">
                  </div> */}
                {!!tabContent?.[selectedTab]?.length && (
                  <div className="flex items-center gap-2 mt-3 flex-wrap">
                    {tabContent?.[selectedTab].map((item, index) => {
                      return (
                        <button
                          key={index}
                          disabled={loading}
                          onClick={handleFilterChange.bind(
                            null,
                            item,
                            selectedTab
                          )}
                          className={classNames(
                            "min-w-[50px] text-xs px-2 outline-none border-b border-b-transparent hover:border-b-gray-400",
                            {
                              "border-b-gray-400 font-medium":
                                filterValue?.[selectedTab] &&
                                filterValue?.[selectedTab].includes(item.ID),
                            }
                          )}
                        >
                          {item.Name}
                        </button>
                      );
                    })}
                  </div>
                )}
                {selectedTab === "carat" && (
                  <div className=" mt-3">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleFilterChange(value, "carat");
                      }}
                    >
                      <div className="flex gap-2 items-center ">
                        <div className="">
                          <label htmlFor="caratFrom">From : </label>
                          <input
                            type="text"
                            className="outline-none px-2 py-0.5 w-16 border rounded"
                            id="caratFrom"
                            value={value[0]}
                            name="caratFrom"
                            onChange={(e) => {
                              const { value: val } = e.target;
                              if (
                                numberRegexWithDecimals.test(val) &&
                                val < value[1]
                              ) {
                                setValue([val, value[1]]);
                              }
                            }}
                          />
                        </div>
                        <div className="">
                          <label htmlFor="toFrom">To : </label>
                          <input
                            type="text"
                            className="outline-none px-2 py-0.5 w-16 border rounded"
                            id="toFrom"
                            name="toFrom"
                            value={value[1]}
                            onChange={(e) => {
                              const { value: val } = e.target;
                              if (numberRegexWithDecimals.test(val)) {
                                setValue([value[0], val]);
                              }
                            }}
                          />
                        </div>
                        <button
                          type="submit"
                          disabled={loading}
                          className="disabled:opacity-80 disabled:cursor-not-allowed border border-gray-400 px-2 rounded text-sm bg-gray-500 text-white py-1"
                        >
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                )}

                {selectedTab === "location" && (
                  <div className="flex items-center gap-2 mt-3 flex-wrap">
                    {[...new Set(stockData?.map((i) => i?.Location))]?.map(
                      (item, index) => (
                        <button
                          key={index}
                          disabled={loading}
                          onClick={() => {
                            setLocationFilter(item);
                            setPageNumber(1);
                          }}
                          className={classNames(
                            `min-w-[50px] text-xs px-2 outline-none border-b border-b-transparent sm:hover:border-b-gray-400 ${
                              locationFilter === item &&
                              "border-b-gray-400 font-medium"
                            }`
                          )}
                        >
                          {item}
                        </button>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="container w-full pt-2 mx-auto">
              <div className="">
                {!!stockData?.length
                  ? stockData?.map((item, index) => {
                      const certiUrl =
                        item.LAB === "GIA"
                          ? `https://www.gia.edu/report-check?reportno=${item.CERTID}`
                          : `https://www.igi.org/viewpdf.php?r=${item.CERTID}`;
                      return (
                        <div
                          key={index}
                          ref={
                            index + 1 === stockData?.length
                              ? lastDiamondElementRef
                              : dummyRef
                          }
                          className="w-full py-2"
                        >
                          <div className="bg-white lg:flex hidden items-center lg:gap-4 gap-2 sm:flex-row flex-col shadow rounded-lg hover:shadow-md py-4 sm:px-6 px-4">
                            <div className="w-full">
                              <div className="">
                                <div className="w-full grid grid-cols-9 gap-2">
                                  <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                    Shape :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.SHAPE}
                                    </span>
                                  </div>
                                  <div className="font-bold col-span-1 xl:text-sm text-[13px] mb-2">
                                    Carat :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.CARAT}
                                    </span>
                                  </div>
                                  <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                    Color :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.COLOUR}
                                    </span>
                                  </div>
                                  <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                    Clarity :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.CLARITY}
                                    </span>
                                  </div>
                                  <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-2">
                                    Cut / Polish / Sym :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.CUTPOLISHSYM}
                                    </span>
                                  </div>
                                  <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-2">
                                    <div className="">
                                      Cert :{" "}
                                      <span className="font-normal xl:text-[14px] text-[13px]">{`${item.LAB}-${item.CERTID}`}</span>
                                    </div>
                                  </div>
                                  <div className="font-bold col-span-1 xl:text-sm text-[13px]">
                                    Location :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px] ">{`${
                                      item.Location || "---"
                                    }`}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <div className="w-full grid grid-cols-9 gap-2">
                                  <div className="font-bold xl:text-sm text-[13px] mt-2 sm:col-span-1 col-span-2">
                                    Stock ID :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.StoneNo}
                                    </span>
                                  </div>
                                  <div className="font-bold col-span-2 xl:text-sm text-[13px] mt-2">
                                    Measurement :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.MEASUREMENT}
                                    </span>
                                  </div>
                                  <div className="font-bold col-span-1 xl:text-sm text-[13px] mt-2">
                                    Rbp :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.RAP}
                                    </span>
                                  </div>
                                  <div className="font-bold col-span-2 xl:text-sm text-[13px] mt-2">
                                    Rate(USD) :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {Math.round(+item.Price || 0)}
                                    </span>
                                  </div>
                                  <div className="font-bold xl:text-sm text-[13px] mt-2 col-span-2">
                                    <div>
                                      Amount(USD) :{" "}
                                      <span className="font-normal xl:text-[14px] text-[13px]">
                                        {Math.round(+item.Amount || 0)}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="min-w-fit font-bold xl:text-sm text-[13px] mr-3 mt-2 col-span-1">
                                    {item.IsAvailable === 1 ? (
                                      <span className="rounded text-xs border border-black text-black font-medium px-2 py-1 font-Poppins">
                                        Stock
                                      </span>
                                    ) : item.IsAvailable === 2 ? (
                                      <span className="rounded text-xs bg-[#fef3c7] text-[#d97706] border border-[#d97706] font-medium px-2 py-1 font-Poppins">
                                        Jewelry Order
                                      </span>
                                    ) : (
                                      <span className="rounded text-xs bg-[#fef3c7] text-[#d97706] border border-[#d97706] font-medium px-2 py-1 font-Poppins">
                                        Memo
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="grid grid-cols-4 lg:gap-4 gap-2 items-center">
                              {!!item.ImageUrl?.includes("https") ? (
                                <div className="sm:w-10 sm:h-10 w-14 h-14 bg-gray-300 rounded">
                                  <ModalImage
                                    small={item.ImageUrl}
                                    large={item.ImageUrl}
                                    className="sm:w-10 sm:h-10 w-14 h-14 rounded object-contain"
                                    alt={`Stock ID: ${item.StoneNo}`}
                                  />
                                </div>
                              ) : (
                                <div className="sm:w-10 sm:h-10 w-14 h-14 flex justify-center items-center bg-gray-300 rounded">
                                  <MdOutlineHideImage
                                    fontSize={24}
                                    className="opacity-60"
                                  />
                                </div>
                              )}
                              {!!item?.["360Url"]?.includes("https") ? (
                                <Tooltip title={"Click to play video"}>
                                  <div className="sm:w-10 sm:h-10 w-14 h-14 cursor-pointer flex justify-center items-center bg-gray-100 rounded">
                                    <CgPlayButtonO
                                      className="text-2xl text-gray-600 "
                                      // onClick={handleVideoModalOpen.bind(
                                      //   null,
                                      //   item
                                      // )}
                                      onClick={() => {
                                        window.open(item?.["360Url"], "_blank");
                                      }}
                                    />
                                    {/* <video
                                  className="sm:w-10 sm:h-10 w-14 h-14 rounded object-cover cursor-pointer"
                                  autoPlay
                                  loop
                                  muted
                                  >
                                  <source
                                    src={item?.['360Url']}
                                    type="video/mp4"
                                    />
                                  </video> */}
                                  </div>
                                </Tooltip>
                              ) : (
                                <div className="sm:w-10 sm:h-10 w-14 h-14 flex justify-center items-center bg-gray-300 rounded">
                                  <MdOutlineVideocamOff
                                    fontSize={24}
                                    className="opacity-60"
                                  />
                                </div>
                              )}
                              {!!["gia", "igi"].includes(
                                item.LAB.toLowerCase()
                              ) ? (
                                <div className="sm:w-10 sm:h-10 w-14 h-14 bg-gray-100 rounded p-1">
                                  <a
                                    href={certiUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="w-full h-full"
                                  >
                                    <img
                                      className="w-full h-full"
                                      src={
                                        item.LAB.toLowerCase() === "gia"
                                          ? Gai
                                          : Igi
                                      }
                                      alt={item.LAB.toLowerCase()}
                                    />
                                  </a>
                                </div>
                              ) : (
                                <div className="sm:w-10 sm:h-10 w-14 h-14 flex justify-center items-center bg-gray-300 rounded">
                                  <TbCertificate
                                    fontSize={24}
                                    className="opacity-60"
                                  />
                                </div>
                              )}
                              <div className="sm:w-10 sm:h-10 w-14 h-14 flex justify-center items-center bg-gray-100 rounded">
                                <div className="flex items-center gap-[10px]">
                                  <div className="flex items-center justify-end">
                                    <Menu
                                      as="div"
                                      className="h-[20px] text-left relative"
                                    >
                                      <Menu.Button>
                                        <GoKebabVertical className="text-[20px] text-[#1e1e1e]" />
                                      </Menu.Button>
                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items className="absolute z-50 top-[22px] right-0 mt-2 w-56  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          <div className="px-1 py-1 ">
                                            <Menu.Item>
                                              {({ active }) => (
                                                <button
                                                  className={`${
                                                    active
                                                      ? "bg-[#1e293b] text-white"
                                                      : "text-gray-900"
                                                  } group flex w-full items-center gap-[5px] rounded-md p-2 text-[13px] font-Poppins`}
                                                  onClick={handleEdit.bind(
                                                    null,
                                                    item
                                                  )}
                                                >
                                                  <BiEdit className="text-[18px]" />
                                                  Edit
                                                </button>
                                              )}
                                            </Menu.Item>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <button
                                                  className={`${
                                                    active
                                                      ? "bg-[#1e293b] text-white"
                                                      : "text-[#1e293b]"
                                                  } group flex w-full items-center gap-[5px] rounded-md p-2 text-[13px] font-Poppins`}
                                                  onClick={() =>
                                                    handleDelete(item.STOCKNO)
                                                  }
                                                >
                                                  <AiOutlineDelete className="text-[18px]" />
                                                  Delete
                                                </button>
                                              )}
                                            </Menu.Item>
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bg-white lg:hidden flex items-center gap-2 sm:flex-row flex-col shadow rounded-lg overflow-hidden hover:shadow-md py-4 sm:px-6 px-4">
                            <div className="w-full">
                              <div className="grid grid-cols-2 gap-1">
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Shape :{" "}
                                  <span className="font-normal">
                                    {item.SHAPE}
                                  </span>
                                </div>
                                <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                  Carat :{" "}
                                  <span className="font-normal">
                                    {item.CARAT}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Color :{" "}
                                  <span className="font-normal">
                                    {item.COLOUR}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Clarity :{" "}
                                  <span className="font-normal">
                                    {item.CLARITY}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Cut/Polish/Sym :{" "}
                                  <span className="font-normal">
                                    {item.CUTPOLISHSYM}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Cert :{" "}
                                  <span className="font-normal">
                                    {item.CERTID}
                                  </span>
                                </div>

                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Stock ID :{" "}
                                  <span className="font-normal">
                                    {item.StoneNo}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  {item.IsAvailable === 1 ? (
                                    <span className="rounded text-[11px] bg-green-100 border border-green-600 text-green-600 font-medium px-2 py-0.5 font-Poppins">
                                      Stock
                                    </span>
                                  ) : item.IsAvailable === 2 ? (
                                    <span className="rounded text-[11px] bg-[#fef3c7] text-[#d97706] border border-[#d97706] font-medium px-2 py-0.5 font-Poppins">
                                      Jewelry Order
                                    </span>
                                  ) : (
                                    <span className="rounded text-[11px] bg-red-100 text-red-600 border border-red-600 font-medium px-2 py-0.5 font-Poppins">
                                      Memo
                                    </span>
                                  )}
                                </div>
                                <div className="font-bold col-span-2 sm:text-sm text-[12px] flex justify-between items-center flex-wrap">
                                  <div>
                                    Measurement :{" "}
                                    <span className="font-normal">
                                      {item.MEASUREMENT}
                                    </span>
                                  </div>
                                  <div className="min-w-fit w-1/2 font-bold xl:text-sm text-[12px]">
                                    Location :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px] ">{`${
                                      item.Location || "---"
                                    }`}</span>
                                  </div>
                                </div>
                                <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                  Rate(USD) :{" "}
                                  <span className="font-normal">
                                    {Math.round(+item.Price)}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Amount(USD) :{" "}
                                  <span className="font-normal">
                                    {Math.round(+item.Amount)}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-4 items-center flex-row">
                              {!!item.ImageUrl ? (
                                <div className="w-10 h-10 bg-gray-300 rounded">
                                  <ModalImage
                                    small={item.ImageUrl}
                                    large={item.ImageUrl}
                                    className="w-10 h-10 rounded object-contain"
                                    alt={`Stock ID: ${item.StoneNo}`}
                                  />
                                </div>
                              ) : (
                                <div className="sm:w-10 sm:h-10 w-14 h-14 flex justify-center items-center bg-gray-300 rounded">
                                  <MdOutlineHideImage
                                    fontSize={24}
                                    className="opacity-60"
                                  />
                                </div>
                              )}
                              {!!item?.["360Url"] ? (
                                <Tooltip title="Click to play video">
                                  <div className="w-10 h-10 rounded cursor-pointer flex justify-center items-center bg-gray-100">
                                    <CgPlayButtonO
                                      className="text-2xl text-gray-600 "
                                      // onClick={handleVideoModalOpen.bind(
                                      //   null,
                                      //   item
                                      // )}
                                      onClick={() => {
                                        window.open(item?.["360Url"], "_blank");
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              ) : (
                                <div className="sm:w-10 sm:h-10 w-14 h-14 flex justify-center items-center bg-gray-300 rounded">
                                  <MdOutlineVideocamOff
                                    fontSize={24}
                                    className="opacity-60"
                                  />
                                </div>
                              )}
                              {!!["gia", "igi"].includes(
                                item.LAB.toLowerCase()
                              ) ? (
                                <div className="w-10 h-10 rounded bg-gray-100 p-1">
                                  <a
                                    href={certiUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="w-full h-full"
                                  >
                                    <img
                                      className="w-full h-full"
                                      src={
                                        item.LAB.toLowerCase() === "gia"
                                          ? Gai
                                          : Igi
                                      }
                                      alt={item.LAB.toLowerCase()}
                                    />
                                  </a>
                                </div>
                              ) : (
                                <div className="w-10 h-10 flex justify-center items-center bg-gray-300 rounded">
                                  <TbCertificate
                                    fontSize={24}
                                    className="opacity-60"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : !loading && (
                      <div className="text-[14px] text-gray-500 font-[600] text-center">
                        No Data
                      </div>
                    )}
                {loading && (
                  <div className="text-center">
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default DiamondStockList;
