import React from "react";
import { useState } from "react";
import Pagination from "./Pagination";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { TiArrowBack } from "react-icons/ti";
import { fetchMenu } from "../../store/Slices/menuSlice";
import { deleteMenuData, getMenuDataById } from "../../Services/menuService";
import AddSubMenu from "./Modals/AddSubMenu";
import swal from "sweetalert";
import usePagination from "../../Hooks/use-Pagination";
import { useCallback } from "react";
import useApi from "../../Hooks/use-Api";
import NoDataText from "../shared/NoDataText";
import Loader from "./common/Loader";

const SubMenuList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const { id } = useParams();
  const [fetchSubmenuData, menuData, loading, error] = useApi(getMenuDataById);
  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data: menuData ? menuData.stchildrenMenu : [],
      itemsPerPage: 10,
    });

  useEffect(() => {
    fetchSubmenuData(id);
  }, [fetchSubmenuData, id]);

  const handleEdit = (id) => {
    setEditId(id);
    setIsOpen(true);
  };

  const handleDelete = async (id) => {
    const willDelete = await swal({
      title: "Are you sure?1",
      text: "Are you sure that you want to delete this file?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });
    if (willDelete) {
      const res = await deleteMenuData(id);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchSubmenuData(id);
      }
    }
  };

  return (
    <div className="flex flex-col flex-1 w-full z-0 h-[90vh] bg-gray-100">
      <AddSubMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        editId={editId}
        setEditId={setEditId}
        data={menuData?.stchildrenMenu || []}
        fetchData={fetchSubmenuData}
      />
      <main className="h-full overflow-y-auto">
        <div className=" grid px-6 mx-auto">
          <div className="flex items-center justify-end gap-[10px]">
            <Link
              to={"/admin/menu"}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
            >
              <TiArrowBack className="text-[18px]" /> Back
            </Link>
            <button
              onClick={() => setIsOpen(true)}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
            >
              <BsPlusLg className="text-[10px]" /> Add
            </button>
          </div>
          <div className="shadow-md sm:rounded-lg overflow-hidden mt-4">
            <div className="relative">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-4 w-[20px]">
                      Image
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Menu Name
                    </th>
                    <th
                      scope="col"
                      colSpan={2}
                      className="px-6 py-4 w-[100px] text-center"
                    >
                      Y | N
                    </th>
                    <th scope="col" className="px-6 py-4 w-[100px] text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && currentData?.length
                    ? currentData.map((itm, i) => {
                        return (
                          <tr
                            key={i}
                            className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                          >
                            <th
                              scope="row"
                              className="px-6 py-1.5 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              <img
                                src={itm.stImageURL}
                                className="w-[40px] h-[40px] rounded"
                                alt="stImageURL"
                              />
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-1.5 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {itm.stMenuName}
                            </th>
                            <td colSpan={2} className="px-6 py-1.5 text-center">
                              {itm.flgIsActive === "1" ? (
                                <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                  Y
                                </span>
                              ) : (
                                <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                  N
                                </span>
                              )}
                            </td>
                            <td className="px-6 py-1.5">
                              <div className="flex items-center gap-[10px]">
                                <button
                                  onClick={handleEdit.bind(
                                    null,
                                    itm.unMenuIdentifier
                                  )}
                                  type="button"
                                >
                                  <BiEdit className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                                </button>
                                <button
                                  type="button"
                                  onClick={handleDelete.bind(
                                    null,
                                    itm.unMenuIdentifier
                                  )}
                                >
                                  <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : !loading && (
                        <tr>
                          <td colSpan={4}>
                            <NoDataText text={"No Data found!"} />
                          </td>
                        </tr>
                      )}
                  {loading && (
                    <tr>
                      <td colSpan={4} className="h-60">
                        <Loader />
                      </td>
                    </tr>
                  )}
                  <tr className="bg-white w-full border-b dark:bg-gray-900 dark:border-gray-700 odd:bg-gray-900"></tr>
                </tbody>
              </table>
            </div>
            <Pagination
              totalItems={menuData?.stchildrenMenu?.length || 0}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default SubMenuList;
