import { Route, Routes } from "react-router-dom";
import Login from "./Components/Admin/Login";
import ForgotPassword from "./Components/Admin/ForgotPassword";
import Register from "./Components/Admin/Register";
import AdminPanel from "./Pages/AdminPanel";
import Dashboard from "./Components/Admin/Dashboard";
import Order from "./Components/Admin/Order";
import Product from "./Components/Admin/Product";
import SearchedResult from "./Pages/SearchedResult";
import Setting from "./Components/Admin/Setting";
import UserProfile from "./Components/Admin/UserProfile";
import Main from "./Pages/Main";
import ProductHome from "./Pages/Product";
import UserRole from "./Components/Admin/UserRole";
import CategoryList from "./Components/Admin/CategoryList";
import AttributeList from "./Components/Admin/AttributeList";
import AttributeValueList from "./Components/Admin/AttributeValueList";
import ProductDetail from "./Pages/ProductDetail";
import Checkout from "./Pages/Checkout";
import AddProductDetails from "./Components/Admin/AddProductDetails";
import DiamondList from "./Pages/DiamondList";
import SnapOwnDesign from "./Pages/SnapOwnDesign";
import MasterMenuList from "./Components/Admin/Pages/MasterMenuList";
import TransactionMenuList from "./Components/Admin/Pages/TransactionMenuList";
import ReportMenuList from "./Components/Admin/Pages/ReportMenuList";
import UtilityMenuList from "./Components/Admin/Pages/UtilityMenuList";
import { Toaster } from "react-hot-toast";
import CategoryStyleList from "./Components/Admin/CategoryStyleList";
import DesignerList from "./Components/Admin/DesignerList";
import SupplierList from "./Components/Admin/SupplierList";
import SnapAndPost from "./Components/Admin/SnapAndPost";
import DiamondStockList from "./Components/Admin/DiamondStockList";
import CalibratedStockList from "./Components/Admin/CalibratedStockList";
import StockClarityList from "./Components/Admin/StockClarityList";
import StockColorList from "./Components/Admin/StockColorList";
import TagList from "./Components/Admin/TagList";
import StepTwo from "./Components/Admin/addProductsSteps/StepTwo";
import StepFour from "./Components/Admin/addProductsSteps/StepFour";
import SideStonePriceList from "./Components/Admin/SideStonePriceList";
import SnapPostList from "./Components/Admin/SnapPostList";
import StockSizeList from "./Components/Admin/StockSizeList";
import ToolsMenu from "./Components/Admin/Pages/ToolsMenu";
import MenuList from "./Components/Admin/MenuList";
import SubMenuList from "./Components/Admin/SubMenuList";
import About from "./Pages/About";
import AddAgentModule from "./Components/Admin/AddAgentModule";
import OrderList from "./Components/Admin/OrderList";
import PlaceOrderList from "./Components/Admin/PlaceOrderList";
import Diamond from "./Components/LandingComponent/Diamond";
import DiamondShapSelection from "./Components/LandingComponent/DiamondShapSelection";
import Calibrated from "./Components/LandingComponent/Calibrated";
import Mounting from "./Components/LandingComponent/Mounting";
import DiamondListNew from "./Components/LandingComponent/DiamondListNew";
import Home from "./Pages/Home";
import HomeNew from "./Pages/HomeNew";
import DiamondRequestList from "./Components/Admin/DiamondRequestList";
import NotFoundPage from "./Pages/NotFoundPage";
import StockListPage from "./Pages/StockListPage";
import ECatelog from "./Pages/ECatelog";
import "./App.css";
import "react-loading-skeleton/dist/skeleton.css";
import AgentSelection from "./Components/LandingComponent/AgentSelection";
import DesignCatalog from "./Components/Admin/DesignCatalog";
import AgentMenu from "./Components/Admin/Pages/AgentMenu";
import { usePageTracking } from "./Hooks/use-page-tracking";
import ECatalog from "./Components/Admin/Catalog";
import Customizejewelry from "./Pages/Customizejewelry";
import ReadyToShipProduct from "./Components/Admin/ReadyToShipProduct";
import MountingMaster from "./Components/Admin/Mounting";
import AddReadyToShip from "./Components/Admin/AddReadyToShip";
import AddMounting from "./Components/Admin/AddMounting";
import ReadyToShip from "./Pages/ReadyToShip";
import ReadyToShipDetails from "./Pages/ReadyToShipDetail";

function App() {
  usePageTracking();

  return (
    <>
      <Toaster position="top-center" reverseOrder={true} />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/admin" element={<AdminPanel />}>
          {/* <Route path="/admin/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/admin/order" element={<Order />} /> */}
          <Route path="/admin/orderlist" element={<OrderList />} />
          {/* <Route path="/admin/placeorderlist" element={<PlaceOrderList />} /> */}
          {/* <Route
            path="/admin/diamondsrequestlist"
            element={<DiamondRequestList />}
          /> */}
          {/* <Route path="/admin/product" element={<Product />} /> */}
          {/* <Route path="/admin/readytoship" element={<ReadyToShipProduct />} /> */}
          {/* <Route path="/admin/mounting" element={<MountingMaster />} /> */}
          <Route path="/admin/masterMenu" element={<MasterMenuList />} />
          {/* <Route path="/admin/tool" element={<ToolsMenu />} /> */}
          <Route path="/admin/menu" element={<MenuList />} />
          <Route
            path="/admin/transactionMenu"
            element={<TransactionMenuList />}
          />
          {/* <Route path="/admin/reportMenu" element={<ReportMenuList />} /> */}
          <Route path="/admin/agent" element={<SnapAndPost />} />
          <Route path="/admin/agentMenu" element={<AgentMenu />} />
          <Route path="/admin/agent-selection" element={<DesignCatalog />} />
          <Route
            path="/admin/diamondStockList"
            element={<DiamondStockList />}
          />
          <Route
            path="/admin/calibrated-stock"
            element={<CalibratedStockList />}
          />
          <Route
            path="/admin/sideStonePriceList"
            element={<SideStonePriceList />}
          />
          <Route
            path="/admin/StockClarityList"
            element={<StockClarityList />}
          />
          <Route path="/admin/StockColorList" element={<StockColorList />} />
          <Route path="/admin/StockSizeList" element={<StockSizeList />} />
          <Route path="/admin/utilityMenu" element={<UtilityMenuList />} />
          <Route path="/admin/addproduct" element={<AddProductDetails />} />
          <Route path="/admin/addproduct/:id" element={<AddProductDetails />} />
          {/* <Route path="/admin/addreadytoship" element={<AddReadyToShip />} />
          <Route path="/admin/addreadytoship/:id" element={<AddReadyToShip />} />
          <Route path="/admin/addmounting" element={<AddMounting />} />
          <Route path="/admin/addmounting/:id" element={<AddMounting />} /> */}
          {/* <Route path="/admin/categorylist" element={<CategoryList />} /> */}
          <Route
            path="/admin/categoryStylelist/:id"
            element={<CategoryStyleList />}
          />
          {/* <Route path="/admin/taglist" element={<TagList />} /> */}
          {/* <Route path="/admin/designerlist" element={<DesignerList />} /> */}
          <Route path="/admin/stone/:id" element={<StepTwo />} />
          <Route path="/admin/subMenu/:id" element={<SubMenuList />} />
          <Route path="/admin/variation/:id" element={<StepFour />} />
          {/* <Route path="/admin/supplierlist" element={<SupplierList />} /> */}
          <Route path="/admin/attributelist" element={<AttributeList />} />
          {/* <Route path="/admin/snap&postlist" element={<SnapPostList />} /> */}
          <Route path="/admin/agentcal/:id" element={<AddAgentModule />} />
          <Route
            path="/admin/attributevaluelist/:id"
            element={<AttributeValueList />}
          />
          <Route path="/admin/user-role" element={<UserRole />} />
          <Route path="/admin/setting" element={<Setting />} />
          <Route path="/admin/profile" element={<UserProfile />} />
        </Route>
        <Route path="/" element={<Home />} />
        {/* <Route path="/new" element={<HomeNew />} />
        <Route path="/jewelry" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/diamondlist" element={<DiamondList />} /> */}
        <Route path="/diamonds" element={<Diamond />} />
        <Route path="/diamonds-shapes" element={<DiamondShapSelection />} />
        <Route path="/calibrated" element={<Calibrated />} />
        {/* <Route path="/diamonds-new" element={<DiamondListNew />} />
        <Route path="/calibrated" element={<Calibrated />} />
        <Route path="/mounting" element={<Mounting />} />
        <Route path="/mounting/:id" element={<Mounting />} />
        <Route path="/fg-stocklist" element={<AgentSelection />} />
        <Route path="/product" element={<ProductHome />} />
        <Route path="/searched-result" element={<SearchedResult />} />
        <Route path="/customized-jewelry" element={<Customizejewelry />} />
        <Route path="/readytoship" element={<ReadyToShip />} />
        <Route path="/E-Catelog" element={<ECatelog />} />
        <Route path="/productdetail/:id" element={<ProductDetail />} />
        <Route path="/readytoshipdetail/:id" element={<ReadyToShipDetails />} />
        <Route path="/stockList/:id" element={<StockListPage />} />
        <Route path="/snap-own-design" element={<SnapOwnDesign />} />
        <Route path="/checkout" element={<Checkout />} /> */}
        <Route path="*" element={<NotFoundPage />} />
        {/* <Route path="/api/Catalog/:id" element={<ECatalog />} /> */}
      </Routes>
    </>
  );
}

export default App;
