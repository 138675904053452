import { Fragment, useState, useEffect } from "react";
import { debounce } from "@mui/material";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import AddToCartModal from './addToCartModal'
import Popover from "@mui/material/Popover";
import Divider from '@mui/material/Divider';

// const sizes = [
//   {
//     name: "2.30*1.30",
//     title: "Regional Paradigm Technician",
//     role: "Admin",
//     email: "janecooper@example.com",
//     telephone: "+1-202-555-0170",
//     imageUrl:
//       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
//     lot: 5,
//     pcs: 25,
//     carat: 0.007,
//   },
//   {
//     name: "2.65*1.45",
//     title: "Regional Paradigm Technician",
//     role: "Admin",
//     email: "janecooper@example.com",
//     telephone: "+1-202-555-0170",
//     imageUrl:
//       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
//     lot: 12,
//     pcs: 250,
//     carat: 0.107,
//   },
//   {
//     name: "2.80*1.30",
//     title: "Regional Paradigm Technician",
//     role: "Admin",
//     email: "janecooper@example.com",
//     telephone: "+1-202-555-0170",
//     imageUrl:
//       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
//     lot: 120,
//     pcs: 1000,
//     carat: 0.0064,
//   },
//   {
//     name: "3.00*1.30",
//     title: "Regional Paradigm Technician",
//     role: "Admin",
//     email: "janecooper@example.com",
//     telephone: "+1-202-555-0170",
//     imageUrl:
//       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
//     lot: 85,
//     pcs: 10,
//     carat: 12.05254,
//   },
//   {
//     name: "3.00*1.50",
//     title: "Regional Paradigm Technician",
//     role: "Admin",
//     email: "janecooper@example.com",
//     telephone: "+1-202-555-0170",
//     imageUrl:
//       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
//     lot: 6,
//     pcs: 105,
//     carat: 0.01,
//   },
//   {
//     name: "3.00*1.50",
//     title: "Regional Paradigm Technician",
//     role: "Admin",
//     email: "janecooper@example.com",
//     telephone: "+1-202-555-0170",
//     imageUrl:
//       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
//     lot: 6,
//     pcs: 105,
//     carat: 0.01,
//   },
//   // {
//   //   name: "3.00*1.70",
//   //   title: "Regional Paradigm Technician",
//   //   role: "Admin",
//   //   email: "janecooper@example.com",
//   //   telephone: "+1-202-555-0170",
//   //   imageUrl:
//   //     "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
//   //   lot: 55,
//   //   pcs: 2500,
//   //   carat: 0.024,
//   // },
// ];

export default function CardList(props) {
  const { handleCurrentNavKey, handleCurrentSize, parentState } = props;

  const ShapeSieveMM = useSelector(
    (state) => state?.calibrated?.data?.ShapeSieveMM
  );

  const [selected, setSelected] = useState();
  const [categoryStyleData, setCategoryStyleData] = useState([]);

  const { loading } = useSelector((state) => state?.calibrated);

  const [state, setState] = useState({
    sizesData: [],
    sizeFilter: "",
    searchText: "",
    fromCarat: "",
    toCarat: "",
    openModal: false,
    selectedItem: {}
  });

  let { sizesData, sizeFilter, searchText, fromCarat, toCarat, openModal, selectedItem } = state;


  useEffect(() => {
    if (ShapeSieveMM?.length) {
      setState((prevState) => ({
        ...prevState,
        sizesData: ShapeSieveMM?.filter(
          (i) => i?.ShapeCode === parentState?.currentShape?.shapeCode
        )?.map((item, i) => {
          return {
            name: item?.ShapeSize,
            shapeCode: item?.ShapeCode,
            lot: item?.Lot,
            pcs: item?.Pcs,
            carat: item?.Carat,
            shapeMM: item?.ShapeMM,
          };
        }),
      }));
    }
  }, [ShapeSieveMM, parentState?.currentShape, sizeFilter]);

  useEffect(() => {
    handleSizeFilter("");
  }, [parentState?.currentShape]);

  const handleSearchBox = (e) => {
    // dispatch(fetchDesigns(params));
  };

  const handleAddToCart = (item) => {
    setState((prevState) => ({ ...prevState, selectedItem: item, openModal: true }));
  };

  const toggleModal = () => {
    setState((prevState) => ({ ...prevState, openModal: !openModal }));
  };

  const hanldeFilterData = (key, value) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSizeFilter = (filterText) => {
    setState((prevState) => ({ ...prevState, sizeFilter: filterText }));
  };

  const debouncedOnChange = debounce(handleSearchBox, 700);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="fixed lg:sticky t-12 lg:t-0 px-4 py-2 z-40 lg:z-0 w-full lg:w-auto bg-gray-50">
        {/* <div className="w-full flex justify-center">
          <p className="font-semibold text-gray-700 text-xl">
            {parentState?.currentShape?.name}
          </p>
        </div> */}

        <div className="w-full flex justify-around items-center pt-2 px-0">
          <div className="w-full h-full">
            {sizesData?.some((item) => item?.shapeMM === "SM") ? (
              <div className="relative">
                <div
                  className=""
                  aria-hidden="true"
                >
                  {/* <div className="w-full border-t border-gray-300" /> */}
                  <Divider className="capitalize text-xl text-[#1F2937]">{parentState?.currentShape?.name?.toLowerCase()}</Divider>
                </div>
                <div className="absolute flex justify-left items-center h-full bottom-0">
                  <span className="isolate inline-flex -space-x-px rounded-md shadow-sm h-full">
                    <button
                      type="button"
                      className={`relative inline-flex items-center ${
                        sizeFilter === "SM" ? "bg-gray-50" : "bg-white"
                      } px-3 py-0.5 md:py-1.5 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 text-xs md:text-base`}
                      onClick={() => handleSizeFilter("SM")}
                    >
                      Sieve Size
                    </button>
                    <button
                      type="button"
                      className={`relative inline-flex items-center ${
                        sizeFilter === "M" ? "bg-gray-50" : "bg-white"
                      } px-3 py-0.5 md:py-1.5 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 text-xs md:text-base`}
                      onClick={() => handleSizeFilter("M")}
                    >
                      MM Size
                    </button>
                  </span>
                </div>
              </div>
            ) : (
              <div className="relative">
                <div
                  className=""
                  aria-hidden="true"
                >
                  <Divider className="capitalize text-xl text-[#1F2937]">{parentState?.currentShape?.name?.toLowerCase()}</Divider>
                </div>
              </div>
            )}
          </div>

          <div class="relative w-[5%] text-right">
            <div>
              <button
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              >
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </button>
              <Popover
                id={"SearchText"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div class="p-4 w-full">
                  <div class="flex flex-col gap-2">
                    <div className="flex justify-between">
                      <h2 class="text-base font-bold text-gray-700">Filter</h2>
                      <XMarkIcon
                        className="h-5 w-5 text-gray-700 cursor-pointer"
                        aria-hidden="true"
                        onClick={handleClose}
                      />
                    </div>

                    <div>
                      <input
                        type="text"
                        id="search"
                        value={searchText}
                        class="rounded text-sm outline-none border-2 border-gray-400 w-full px-2 py-0.5"
                        onChange={(e) => {
                          hanldeFilterData("searchText", e.target.value);
                        }}
                        placeholder="Search"
                      />
                    </div>

                    {/* <div class="flex items-center gap-2">
                      <div class="flex items-center gap-2">
                        <label class="text-sm" for="caratFrom">
                          From :{" "}
                        </label>
                        <input
                          type="text"
                          class="outline-none px-2 text-xs py-0.5 w-16 border rounded"
                          id="caratFrom"
                          name="caratFrom"
                          value={fromCarat}
                          onChange={(e) => {
                            hanldeFilterData("fromCarat", e.target.value);
                          }}
                          placeholder="Carat"
                        />
                      </div>
                      <div class="flex items-center gap-2">
                        <label class="text-sm" for="toFrom">
                          To :{" "}
                        </label>
                        <input
                          type="text"
                          class="outline-none px-2 text-xs py-0.5 w-16 border rounded"
                          id="toFrom"
                          name="toFrom"
                          value={toCarat}
                          onChange={(e) => {
                            hanldeFilterData("toCarat", e.target.value);
                          }}
                          placeholder="Carat"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>

      <AddToCartModal
        item={selectedItem}
        source="cardlist"
        open={openModal}
        handleClose={toggleModal}
        parentState={parentState}
      />

      <div className="bg-gray-50 lg:h-[calc(100vh-115.2px)] h-[100vh] overflow-scroll">
        <ul
          role="list"
          className="grid gap-x-4 md:gap-x-4 lg:gap-x-4 xl:gap-x-4 gap-y-4 xl:gap-y-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 2xl:grid-cols-7 3xl:grid-cols-9 p-4 pt-20 lg:pt-2"
        >
          {loading
            ? [...Array(12).keys()]?.map((i) => {
                return (
                  <div
                    role="status"
                    class="flex flex-col items-center justify-evenly h-56 max-w-sm bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700 p-2"
                  >
                    <div class="w-10 h-10 bg-gray-200 rounded-full dark:bg-gray-700 m-2"></div>
                    <div className="w-full">
                      <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 m-2"></div>
                      <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 m-2 mt-3"></div>
                      <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 m-2 mt-3"></div>
                    </div>
                  </div>
                );
              })
            : sizesData.map((item) => {
                if (sizeFilter && item.shapeMM !== sizeFilter) {
                  return;
                }

                // Filter
                if (searchText && !item.name.includes(searchText)) return;
                if (fromCarat && item?.carat < fromCarat) return;
                if (toCarat && item?.carat > toCarat) return;

                return (
                  <>
                    <li
                      key={item?.id}
                      className="overflow-hidden group border-gray-200 cursor-pointer relative hover:border hover:border-gray-300 border shadow shadow-gray-100 hover:shadow-2xl duration-300"
                    >
                      <div
                        onClick={() => {
                          handleCurrentNavKey("table-list");
                          handleCurrentSize(item);
                        }}
                      >
                        <div className="flex flex-col items-center gap-x-4 border-b border-gray-900/5 bg-white p-4">
                          <div className="h-14 w-14 p-0 flex-none rounded-full ring-1 ring-gray-900/10 grid place-content-center">
                            <img
                              src={`${
                                parentState?.currentShape?.icon ||
                                "/images/icons/Asscher.png"
                              }`}
                              alt={item?.name}
                              className="h-12 p-1"
                            />
                          </div>
                          <div className="text-sm font-medium leading-6 text-gray-700 mt-1">
                            {item?.name || "N/A"}
                          </div>
                        </div>
                        {/* <div className="invisible group-hover:visible w-full absolute text-center px-4 pt-8 transition transform duration-300 translate-y-8 ease-in-out group-hover:translate-y-0">
                      <div className="bg-gray-50 text-gray-700 py-1 text-sm">
                        View Details
                      </div>
                    </div> */}
                        <dl className="-my-3 divide-y divide-gray-100 px-3 py-4 pt-0 text-sm leading-6 bg-white">
                          {/* <div className="flex justify-between gap-x-4 py-0.5">
                        <dt className="text-gray-500">Lot</dt>
                        <dd className="text-gray-700">{item?.lot || 0}</dd>
                      </div> */}
                          <div className="flex justify-between gap-x-4 py-0.5">
                            <dt className="text-gray-500">Pcs</dt>
                            <dd className="text-gray-700">{item?.pcs || 0}</dd>
                          </div>

                          <div className="flex justify-between gap-x-4 py-0.5">
                            <dt className="text-gray-500">Carat</dt>
                            <dd className="flex items-start gap-x-2">
                              <div className="text-gray-700">
                                {item?.carat || 0}
                              </div>
                            </dd>
                          </div>
                        </dl>

                        <div className="flex justify-center bg-gray-100 text-gray-700 py-1 text-sm">
                          View Details
                        </div>
                        <hr />
                      </div>
                      <div
                        className="flex justify-center bg-[#161b25] text-gray-200 py-1 text-sm opacity-80"
                        onClick={() => handleAddToCart(item)}
                      >
                        Add To Cart
                      </div>
                    </li>
                  </>
                );
              })}
        </ul>
      </div>
    </>
  );
}
